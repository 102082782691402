import React from 'react';
import './Favorites.css';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'; // Regular version
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../features/user/userSlice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../../utils/api';
const Favorites = () => {
    const user = useSelector((state) => state.user.user);
    useEffect(() => {
        console.log("called");
    }, [user])
    console.log(user);
    return (
        <div className="portal-favorites-container">
            <div className='portal-favorites-header'>
                <h4 className="portal-profile-header">Favorites</h4>
            </div>
            <div className='portal-favorites-property-details-title'>
                <div>Property</div>
                <div>Details</div>
            </div>
            <div className='portal-horizontal-line'></div>
            <div className='portal-favorites-property-card-container'>
                {user?.savedProperties?.map((property) => (
                    <FavoritePropertyCard property={property} key={property.id} user={user} />
                ))}
            </div>
        </div>
    );
};
const FavoritePropertyCard = ({ property, user }) => {
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const calculateTimeLeft = () => {
        const difference = +new Date(property.auctionEndDate) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
            };
        }
        return timeLeft;
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 60000); // Update every minute
        return () => clearTimeout(timer);
    });
    const removeProperty = async () => {
        try {
            const response = await axios.put(`${BASE_URL}/api/v1/user/rempve-property`, {
                user_id: user._id,
                product_id: property._id
            });
            dispatch(setUser(response.data.user));
        } catch (err) {
            console.log(err);
        }
    }
    const handleViewDetails = () => {
        window.location.href=`/Property-detail/${property._id}`
    }
    return (
        <div className="portal-favorites-card">
            <div className="portal-favorites-property-image">
                <img src={property.image} alt="Property" />
            </div>
            <div className="portal-favorites-details">
                <div>
                    <p className="portal-favorites-property-address">{property.street} {property.city}, {property.state} {property.zipCode}</p>
                    <p className="portal-favorites-time-left">Time Left: {timeLeft.minutes !== undefined ? (
                        <>
                            {timeLeft.days} days : {timeLeft.hours} hours : {timeLeft.minutes} minutes
                        </>
                    ) : (
                        <span>Time Over</span>
                    )} </p>
                    <p className="portal-favorites-starting-bid">Starting Bid: ${property.startBid}</p>
                    <p className="portal-favorites-status">Status:
                        {timeLeft.minutes !== undefined ? <> Active</> : <> Not Active</>}
                    </p>
                </div>
                <div className='portal-view-details-delete-container'>
                    <button className="portal-favorites-view-details" onClick={handleViewDetails} >View Details</button>
                    <button className="portal-favorites-delete" onClick={removeProperty}><FontAwesomeIcon icon={faTrashAlt} /></button>
                </div>
            </div>
        </div>
    )
}
export default Favorites;
