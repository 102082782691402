import React, { useState } from 'react';
import { Modal, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import axios from 'axios';
import "./EmailOTPModel.css"
import BASE_URL from '../../../utils/api';
const EmailOTPModel = ({ open, onClose, recievedotp, userID, email }) => {
    const [otp, setOtp] = useState(new Array(4).fill(''));
    const [Recievedotp,setRecievedOTP]=useState(recievedotp);
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;
        let newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const sendAgain =async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/v1/user/send-otp`, { email});
            setRecievedOTP(response.data.otp);
            toast.success("OTP sent to your email")
        } catch (error) {
            console.error(error);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const enteredOtp = otp.join('');
        console.log(enteredOtp,Recievedotp);
        console.log(Recievedotp+"hi");
        try {
            if (enteredOtp === recievedotp) { // Replace with actual OTP validation logic
                const response = await axios.put(`${BASE_URL}/api/v1/user/update/${userID}`, { email });
                console.log(response);
                window.location.reload();
            } else {
                toast.error('Invalid OTP');
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    function obfuscateEmail(email) {
        const [localPart, domain] = email.split("@");

        const firstTwo = localPart.slice(0, 2);
        const lastTwo = localPart.slice(-2);
        const obfuscatedLocal = `${firstTwo}*****${lastTwo}`;

        return `${obfuscatedLocal}@${domain}`;
    }
    function allfill(){
        let ans=false;
        otp.forEach((data)=>{
               ans=data!=='';
        })
        return ans;
    }
    return (
        <Modal open={open} onClose={onClose}>
            <Box className="otp-modal-box">
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
                    <Typography id="change-email-title" variant="h6">
                        Change Email
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box alignItems="center" style={{ marginBottom: '20px' }}>
                    Email verification code sent to : <span style={{ color: "rgb(25, 118, 210)" }}> {obfuscateEmail(email)}</span>
                </Box>
                <Box className="email-model-otp-container">
                    <h3>Verify Your Email ID</h3>
                    <form onSubmit={handleSubmit} className='email-modal-otp-input-container-otp-form'>
                        <div>
                            {otp.map((data, index) => (
                                <input
                                    className="email-modelotp-input-container-otp-input"
                                    type="text"
                                    maxLength="1"
                                    key={index}
                                    value={data}
                                    onChange={(e) => handleChange(e.target, index)}
                                    onFocus={(e) => e.target.select()}
                                />
                            ))}
                        </div>
                        <button type="submit" style={{backgroundColor:allfill()?"rgb(25, 118, 210)":""}} className="email-model-otp-input-container-verify-button">Verify</button>
                    </form>
                </Box>
                <Box alignItems="center" style={{ fontSize: "14px" }}>
                    Didn't get the verification code<span style={{ color: "rgb(25, 118, 210)", cursor: "pointer" }} onClick={()=>sendAgain()} > click here</span> to generate new one
                </Box>
            </Box>
        </Modal>
    );
};

// Modal styles for centering and size

export default EmailOTPModel;
