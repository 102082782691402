// AuctionRegistrationForm.jsx
import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faArrowLeft,
  faArrowRight,
  faCheck,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import BuyerInfoStep from "./BuyerInfoStep";
import LegalDisclosuresStep from "./LegalDisclosuresStep";
import ReviewStep from "./ReviewStep";
import ContractStep from "./ContractStep";
import "./AuctionRegistrationForm.css";
import axios from "axios";
import { useSelector } from "react-redux";
import BASE_URL from "../../../utils/api";

const Steps = {
  BUYER_INFO: 1,
  LEGAL_DISCLOSURES: 2,
  REVIEW: 3,
  CONTRACT: 4,
};

const AuctionRegistrationForm = ({
  isOpen,
  onClose,
  currentBid,
  buyersPremium,
  product,
  onRegistrationComplete,
}) => {
  const [activeStep, setActiveStep] = useState(Steps.BUYER_INFO);
  const [selectedForm, setSelectedForm] = useState("");
  const [buyerType, setBuyerType] = useState("individual");
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.user);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    company: "",
    entityType: "",
    taxId: "",
    agreeToTerms: false,
    acknowledgeInspection: false,
    understandFinancing: false,
    acceptPropertyCondition: false,
    acknowledgeClosingTimeline: false,
    confirmInformation: false,
    agreeToContract: false,
    agreeToDeposit: false,
    acceptTerms: false,
  });

  const steps = [
    { number: 1, text: "BUYER INFO" },
    { number: 2, text: "LEGAL DISCLOSURES" },
    { number: 3, text: "REVIEW" },
    { number: 4, text: "CONTRACT" },
  ];
  // Pre-fill user data when form opens
  useEffect(() => {
    if (isOpen && user) {
      setFormData((prev) => ({
        ...prev,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        mobilePhone: user.businessPhone || "",
        email: user.email || "",
        address: user.address || "",
        city: user.city || "",
        state: user.state || "",
        zipCode: user.zipCode || "",
      }));
    }
  }, [isOpen, user]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleNext = () => {
    if (canProceed()) {
      setActiveStep((prev) => prev + 1);
      window.scrollTo(0, 0);
    } else {
      toast.error("Please complete all required fields");
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
    window.scrollTo(0, 0);
  };

  const canProceed = () => {
    switch (activeStep) {
      case Steps.BUYER_INFO:
        const requiredFields = [
          "firstName",
          "lastName",
          "mobilePhone",
          "email",
          "address",
          "city",
          "state",
          "zipCode",
        ];
        if (buyerType === "company") {
          requiredFields.push("company", "entityType", "taxId");
        }
        return requiredFields.every((field) => formData[field]?.trim());

      case Steps.LEGAL_DISCLOSURES:
        return (
          formData.agreeToTerms &&
          formData.acknowledgeInspection &&
          formData.understandFinancing &&
          formData.acceptPropertyCondition &&
          formData.acknowledgeClosingTimeline
        );

      case Steps.REVIEW:
        return formData.confirmInformation;

      case Steps.CONTRACT:
        return (
          formData.agreeToContract &&
          formData.agreeToDeposit &&
          formData.acceptTerms
        );

      default:
        return false;
    }
  };
  const handleSubmit = async () => {
    if (!canProceed()) {
      toast.error("Please complete all required fields");
      return;
    }

    try {
      setLoading(true);

      const requestData = {
        userId: user._id,
        auctionId: product._id,
        buyerInfo: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          mobilePhone: formData.mobilePhone,
          email: formData.email,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zipCode: formData.zipCode,
        },
        buyerType: buyerType,
        companyInfo:
          buyerType === "company"
            ? {
                company: formData.company,
                entityType: formData.entityType,
                taxId: formData.taxId,
              }
            : {},
        legalAgreements: {
          agreeToTerms: formData.agreeToTerms,
          acknowledgeInspection: formData.acknowledgeInspection,
          understandFinancing: formData.understandFinancing,
          acceptPropertyCondition: formData.acceptPropertyCondition,
          acknowledgeClosingTimeline: formData.acknowledgeClosingTimeline,
          agreeToContract: formData.agreeToContract,
          agreeToDeposit: formData.agreeToDeposit,
          acceptTerms: formData.acceptTerms,
        },
        bidAmount: currentBid,
        buyersPremium: buyersPremium,
      };

      const response = await axios.post(
        `${BASE_URL}/api/auction-registration`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      toast.success(
        "Your registration has been submitted successfully! You will be notified once it's approved."
      );

      if (onRegistrationComplete) {
        onRegistrationComplete();
      }

      onClose();
    } catch (error) {
      console.error("Submission error:", error);
      toast.error(
        error.response?.data?.message ||
          "Error submitting form. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };
  const renderStepContent = () => {
    switch (activeStep) {
      case Steps.BUYER_INFO:
        return (
          <BuyerInfoStep
            formData={formData}
            handleInputChange={handleInputChange}
            buyerType={buyerType}
            setBuyerType={setBuyerType}
            selectedForm={selectedForm}
            setSelectedForm={setSelectedForm}
            currentBid={currentBid}
            buyersPremium={buyersPremium}
          />
        );

      case Steps.LEGAL_DISCLOSURES:
        return (
          <LegalDisclosuresStep
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );

      case Steps.REVIEW:
        return (
          <ReviewStep
            formData={formData}
            handleInputChange={handleInputChange}
            product={product}
            currentBid={currentBid}
            buyersPremium={buyersPremium}
            buyerType={buyerType}
          />
        );

      case Steps.CONTRACT:
        return (
          <ContractStep
            formData={formData}
            handleInputChange={handleInputChange}
            product={product}
            currentBid={currentBid}
            buyersPremium={buyersPremium}
          />
        );

      default:
        return null;
    }
  };
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <div className="auction-registration-form-container">
        {/* Close button */}
        <button onClick={onClose} className="auction-registration-form-close">
          <FontAwesomeIcon icon={faX} />
        </button>

        {/* Header */}
        <div className="auction-registration-form-header">
          <h2>Auction Registration</h2>
          <p>Complete this form to register for bidding on this auction.</p>
        </div>

        {/* Progress Steps */}
        <div className="auction-registration-form-progress">
          {steps.map((step) => (
            <div
              key={step.number}
              className={`auction-registration-form-step ${
                activeStep === step.number ? "active" : ""
              } 
                         ${activeStep > step.number ? "completed" : ""}`}
            >
              <div className="auction-registration-form-step-number">
                {activeStep > step.number ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  step.number
                )}
              </div>
              <span className="auction-registration-form-step-text">
                {step.text}
              </span>
            </div>
          ))}
        </div>

        {/* Form Content */}
        <div className="auction-registration-form-content">
          {renderStepContent()}
        </div>

        {/* Navigation Buttons */}
        <div className="auction-registration-form-navigation">
          {activeStep > Steps.BUYER_INFO && (
            <button
              onClick={handleBack}
              className="auction-registration-form-back-button"
              disabled={loading}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
          )}

          {activeStep < Steps.CONTRACT ? (
            <button
              onClick={handleNext}
              disabled={!canProceed() || loading}
              className="auction-registration-form-next-button"
            >
              Next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              disabled={!canProceed() || loading}
              className="auction-registration-form-submit-button"
            >
              {loading ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} spin /> Submitting...
                </>
              ) : (
                "Submit Registration"
              )}
            </button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default AuctionRegistrationForm;
