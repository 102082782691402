import React, { useEffect } from 'react'
import './HomePageBlog.css'; // import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom';
import CardSlider from '../Blog/CardSlider';
import ContactUs from '../HomeComponents/ContactUs/ContactUs';
import NavBackground from '../includes/navBackground';
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMailBulk, faMailForward } from '@fortawesome/free-solid-svg-icons';
import guide from "./assets/guide.png"
import homes from "./assets/homes.png"
import sales from "./assets/sales.png"
import work from "./assets/work.png"
import writterImage from "../../assets/images/vihara/image23.png"
import { useParams } from 'react-router-dom';
const content = {
    "Why-you-should-buy-a-Foreclosure-Home": {
        "image": guide,
        "blogContent": `<div>
          <h2>Reasons to Consider Investing in REO Bank-Owned Homes</h2>
          <ul>
            <li><strong>Below Market Prices:</strong> REO properties are typically sold at a discount, making them an attractive option for investors looking to maximize their purchasing power.</li>
            <li><strong>Potential for Customization:</strong> REO homes often require renovations, offering the chance to customize and add value through improvements.</li>
            <li><strong>High ROI Potential:</strong> Buying at a lower price increases the potential for a higher return on investment (ROI) when the property is resold or rented out.</li>
            <li><strong>Clear Title:</strong> Banks usually clear any liens or encumbrances before selling REO properties, reducing legal risks for investors.</li>
            <li><strong>Less Competition:</strong> Compared to traditional real estate markets, there may be less competition when purchasing REO properties, allowing investors to secure better deals.</li>
            <li><strong>Potential for Appreciation:</strong> Investing in REO properties in areas with potential for economic growth and development can lead to significant property appreciation over time.</li>
          </ul>
          <h2>How to Invest in REO Bank-Owned Homes on Vihara</h2>
          <ol>
            <li><strong>Research the Market:</strong> Understand the REO property market and identify areas with high investment potential.</li>
            <li><strong>Secure your Funds:</strong> Ensure you have the necessary funds or financing in place before starting your search.</li>
            <li><strong>Find REO Listings:</strong> Use the Vihara platform to find available REO properties. You can narrow down your search to location, auction date, and much more.</li>
            <li><strong>Conduct Due Diligence:</strong> Thoroughly inspect the property and review its history to identify any potential issues or necessary repairs.</li>
            <li><strong>Attend the Auction:</strong> Register for the auction. Attend the auction and bid on the property.</li>
            <li><strong>Provide Documents:</strong> If you’re the highest bidder, you’ve won the auction! Now you need to sign the purchase agreement and provide proof of funds.</li>
            <li><strong>Closing, Escrow and Title:</strong> Work with closing representatives and contract representatives who will walk you through the process.</li>
            <li><strong>Gain Possession:</strong> Congrats, you have ownership of the property! Now you can renovate and update your property to increase your investment.</li>
          </ol>
          <h2>Investing in REO Bank-Owned Properties in 3 Easy Steps</h2>
          <ol>
            <li><strong>Pre-Purchase Preparation:</strong> Research the market, secure financing, and find suitable REO listings.</li>
            <li><strong>Attend the Auction:</strong> Attend the auction and bid for the property.</li>
            <li><strong>Post-Auction:</strong> Sign the purchase agreement, work with closing representatives, and gain possession of the property.</li>
          </ol>
          <p>By following these steps, you can successfully invest in REO bank-owned homes and capitalize on the opportunities they present.</p>
        </div>
        `
    }
    ,
    "Why-you-should-buy-a-REO-Bank-Owned-Home": {
        "image": sales,
        "blogContent": `<div>
  <h2>Reasons to Invest in a Foreclosure Property</h2>
  <ul>
    <li><strong>Investment Starters:</strong> If you’re considering becoming a real estate investor, investing in a foreclosure property is a great way to start. Foreclosure properties are typically less expensive so they make a great headway into real estate investment.</li>
    <li><strong>High ROI Potential:</strong> Renovating foreclosure properties can increase their value, allowing for profitable resale or rental opportunities.</li>
    <li><strong>Vihara Plus Point:</strong> Vihara can help you invest in foreclosure properties. We offer a transparent platform to make bidding easier and faster.</li>
  </ul>
  <h2>How to Buy a Foreclosure Property Through Vihara</h2>
  <ol>
    <li><strong>Research First:</strong> Before you start bidding for properties, understand the foreclosure process to avoid any surprises. You can always reach out to our Customer Service team in case you have any questions.</li>
    <li><strong>Gather your Payment:</strong> Make sure you prepare a cashier’s check or ensure funds are in an escrow account so you can bid remotely for a property.</li>
    <li><strong>Find your Property:</strong> Now you can start searching for properties on the Vihara platform. You can narrow down the location of the properties you’re searching for.</li>
    <li><strong>Research the Property:</strong> Before you start bidding and making an offer, make sure you conduct your due diligence to identify any problems with the property.</li>
    <li><strong>Place your Bids:</strong> Make sure you bid strategically in the auction. The property will be sold once the credit bid is reached.</li>
  </ol>
  <h2>Summary of the Foreclosure Auction Process</h2>
  <ol>
    <li><strong>Pre-Auction:</strong> Search for properties, research, and gather your funds.</li>
    <li><strong>Auction Day:</strong> Register to bid, and bring your payment and ID.</li>
    <li><strong>Post-Auction:</strong> Pay for the home, receive the deed, and gain possession.</li>
  </ol>
  <p>Buying a foreclosure property can be a great deal, but it's crucial to understand the process and your ability to handle home improvements. Vihara simplifies the process into three steps:</p>
</div>
`
    },
    "10-Ways-to-Remodel-Your-Foreclosure-Property-for-Maximum-Value": {
        "image": homes,
        "blogContent": `<div>
      <p>Before embarking on any remodeling project, conduct a thorough inspection to identify structural issues, plumbing, electrical systems, and any other areas needing repair. This step ensures that your remodeling efforts are built on a solid foundation.</p>
    
      <h2>Enhance Curb Appeal</h2>
      <p>First impressions matter. Simple improvements like landscaping, a fresh coat of exterior paint, and a new front door can significantly boost curb appeal, making the property more attractive to potential buyers.</p>
    
      <p>Purchasing a foreclosure property offers a unique opportunity for homeowners and investors alike to remodel and increase its value significantly. While these properties often require a bit more TLC, strategic renovations can transform them into highly desirable homes. In this blog, we'll explore 10 effective ways to remodel your foreclosure property to maximize its worth.</p>
    
      <ol>
        <li>
          <h3>Start with a Thorough Inspection</h3>
        </li>
        <li>
          <h3>Modernize the Kitchen</h3>
          <p>The kitchen is often considered the heart of the home. Updating this space can yield a high return on investment. Consider refacing or replacing outdated cabinets, installing new countertops, and upgrading appliances to modern, energy-efficient models.</p>
        </li>
        <li>
          <h3>Revamp the Bathrooms</h3>
          <p>Bathrooms are another critical area where remodeling can add significant value. Updating fixtures, tiling, and lighting can transform an outdated bathroom into a modern, stylish space.</p>
        </li>
        <li>
          <h3>Opt for Energy-Efficient Upgrades</h3>
          <p>Investing in energy-efficient upgrades can increase a property’s value while reducing future utility costs. Consider double-paned windows, high-efficiency appliances, and eco-friendly insulation materials.</p>
        </li>
        <li>
          <h3>Refresh with Paint</h3>
          <p>A fresh coat of paint is one of the most cost-effective ways to rejuvenate a property. Choose neutral colors to appeal to a broad range of buyers and create a sense of space and light.</p>
        </li>
        <li>
          <h3>Focus on Flooring</h3>
          <p>Flooring can dramatically alter the look and feel of a home. If the budget allows, replace old carpets with hardwood or laminate flooring for a cleaner, more modern look.</p>
        </li>
        <li>
          <h3>Create an Open Floor Plan</h3>
          <p>Open floor plans are in high demand. Removing non-structural walls to create a more open, flowing space can make the property feel larger and more welcoming.</p>
        </li>
        <li>
          <h3>Upgrade Lighting Fixtures</h3>
          <p>Good lighting can change the ambiance of a home. Replace dated light fixtures with modern, energy-efficient options to brighten up the space and make it more inviting.</p>
        </li>
        <li>
          <h3>Invest in Landscaping</h3>
          <p>A well-maintained garden can significantly enhance a property's appeal. Simple landscaping, like planting shrubs, flowers, and maintaining the lawn, can make a big difference.</p>
        </li>
      </ol>
    
      <p>Remodeling a foreclosure property requires a strategic approach to maximize its value. Remember, the goal is to create a space that not only looks appealing but also offers functionality and efficiency to future homeowners.</p>
    </div>
    `
    }

}
function HomePageBlog() {
    const location = useLocation();
    const { blogname } = useParams();
    return (
        <div>
            <NavBackground />
            <BlogHeader title={blogname.replace(/-/g, ' ')} image={content[blogname]["image"]} />
            <div className='social-media-subscribe-outer-container'>
                <div className='social-media-subscribe-container' >
                    <div className="home-page-blog-social">
                        <span><FontAwesomeIcon className='my-icon-social-media' icon={faFacebook} /></span>
                        <span ><FontAwesomeIcon className='my-icon-social-media' icon={faTwitter} /></span>
                        <span ><FontAwesomeIcon className='my-icon-social-media' icon={faLinkedin} /></span>
                        <span ><FontAwesomeIcon className='my-icon-social-media' icon={faEnvelope} /></span>
                    </div>
                    <div className="home-page-blog-image-section">
                        <p>Get the latest from Vihara</p>
                        <div className="home-page-blog-subscribe">
                            <button>SUBSCRIBE NOW</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='danger-html' dangerouslySetInnerHTML={{ __html: content[blogname]["blogContent"] }} />
            <CardSlider title={"Read More"} from={"HomePageBlog"} />
            <ContactUs />
        </div>
    )
}

export default HomePageBlog;

const BlogHeader = ({ title, image, content }) => {
    return (
        <>
            <div className="home-page-blog-card">
                <div className="home-page-blog-content">
                    <div>
                        <h4 className='subtitle-blog-header'>AUCTION INSIGHTS</h4>
                        <h2 className="home-page-blog-title">{title}</h2>
                        <p className="home-page-blog-date">May 20, 2024</p>
                    </div>
                    <div className="home-page-blog-author">
                        <img src={writterImage} alt="Author" className="home-page-author-image" />
                        <div className='author-name-container'>
                            <p>Trisha Gupta</p>
                            <p>COO & Co-Founder</p>
                        </div>
                    </div>

                </div>
                <div className='blog-image-contaioner'> <img src={image} alt="Profile" /> </div>
            </div>
        </>
    );
};