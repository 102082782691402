import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckCircle, 
  faTimesCircle, 
  faHourglass, 
  faEye, 
  faFilter, 
  faSync,
  faSpinner,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import './AuctionRegistrationList.css';
import BASE_URL from "../../../utils/api";

// Registration Detail Modal Component
const RegistrationDetailModal = ({ registration, onClose, onUpdateStatus }) => {
  const [loading, setLoading] = useState(false);

  const handleStatusUpdate = async (newStatus) => {
    try {
      setLoading(true);
      await onUpdateStatus(registration._id, newStatus);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  if (!registration) return null;
  const getStatusBadge = (status) => {
    switch (status) {
      case "approved":
        return (
          <span className="registration-status approved">
            <FontAwesomeIcon icon={faCheckCircle} /> Approved
          </span>
        );
      case "rejected":
        return (
          <span className="registration-status rejected">
            <FontAwesomeIcon icon={faTimesCircle} /> Rejected
          </span>
        );
      default:
        return (
          <span className="registration-status pending">
            <FontAwesomeIcon icon={faHourglass} /> Pending
          </span>
        );
    }
  };
  
  const totalPrice = registration.bidAmount + registration.buyersPremium;
  return (
    <div className="registration-detail-modal-overlay">
      <div className="registration-detail-modal">
        <div className="registration-detail-modal-header">
          <h2>Registration Details</h2>
          <button onClick={onClose} className="close-button">×</button>
        </div>
        
        <div className="registration-detail-modal-content">
          <div className="registration-detail-section">
            <h3>Registration Status</h3>
            <div className="registration-status-container">
              {getStatusBadge(registration.status)}
              <div className="registration-date">
                Submitted: {new Date(registration.submittedAt).toLocaleString()}
              </div>
            </div>
          </div>

          <div className="registration-detail-section">
            <h3>Auction Details</h3>
            <div className="registration-auction-info">
              <div className="registration-detail-item">
                <span className="label">Property:</span>
                <span className="value">{registration.auctionId?.street}, {registration.auctionId?.city}, {registration.auctionId?.state}</span>
              </div>
              <div className="registration-detail-item">
                <span className="label">Bid Amount:</span>
                <span className="value">${registration.bidAmount.toLocaleString()}</span>
              </div>
              <div className="registration-detail-item">
                <span className="label">Buyer's Premium:</span>
                <span className="value">${registration.buyersPremium.toLocaleString()}</span>
              </div>
              <div className="registration-detail-item">
                <span className="label">Total Price:</span>
                <span className="value">${totalPrice.toLocaleString()}</span>
              </div>
            </div>
          </div>
          <div className="registration-detail-section">
            <h3>Buyer Details</h3>
            <div className="registration-buyer-info">
              <div className="registration-detail-item">
                <span className="label">Buyer Type:</span>
                <span className="value">{registration.buyerType === 'individual' ? 'Individual' : 'Company'}</span>
              </div>
              <div className="registration-detail-item">
                <span className="label">Name:</span>
                <span className="value">{registration.buyerInfo.firstName} {registration.buyerInfo.lastName}</span>
              </div>
              <div className="registration-detail-item">
                <span className="label">Email:</span>
                <span className="value">{registration.buyerInfo.email}</span>
              </div>
              <div className="registration-detail-item">
                <span className="label">Phone:</span>
                <span className="value">{registration.buyerInfo.mobilePhone}</span>
              </div>
              <div className="registration-detail-item">
                <span className="label">Address:</span>
                <span className="value">
                  {registration.buyerInfo.address}, {registration.buyerInfo.city}, {registration.buyerInfo.state} {registration.buyerInfo.zipCode}
                </span>
              </div>
              
              {registration.buyerType === 'company' && (
                <>
                  <div className="registration-detail-item">
                    <span className="label">Company:</span>
                    <span className="value">{registration.companyInfo.company}</span>
                  </div>
                  <div className="registration-detail-item">
                    <span className="label">Entity Type:</span>
                    <span className="value">{registration.companyInfo.entityType}</span>
                  </div>
                  <div className="registration-detail-item">
                    <span className="label">Tax ID:</span>
                    <span className="value">{registration.companyInfo.taxId}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="registration-detail-section">
            <h3>Legal Agreements</h3>
            <div className="registration-agreements">
              <ul className="agreement-list">
                {registration.legalAgreements.agreeToTerms && (
                  <li><FontAwesomeIcon icon={faCheckCircle} /> Terms and Conditions</li>
                )}
                {registration.legalAgreements.acknowledgeInspection && (
                  <li><FontAwesomeIcon icon={faCheckCircle} /> Property Inspection</li>
                )}
                {registration.legalAgreements.understandFinancing && (
                  <li><FontAwesomeIcon icon={faCheckCircle} /> Financing Requirements</li>
                )}
                {registration.legalAgreements.acceptPropertyCondition && (
                  <li><FontAwesomeIcon icon={faCheckCircle} /> Property Condition</li>
                )}
                {registration.legalAgreements.acknowledgeClosingTimeline && (
                  <li><FontAwesomeIcon icon={faCheckCircle} /> Closing Timeline</li>
                )}
                {registration.legalAgreements.agreeToContract && (
                  <li><FontAwesomeIcon icon={faCheckCircle} /> Purchase Agreement</li>
                )}
                {registration.legalAgreements.agreeToDeposit && (
                  <li><FontAwesomeIcon icon={faCheckCircle} /> Earnest Money Deposit</li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="registration-detail-modal-actions">
          {registration.status === 'pending' && (
            <>
              <button 
                className="approve-button"
                onClick={() => handleStatusUpdate('approved')}
                disabled={loading}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faCheckCircle} />} 
                Approve
              </button>
              <button 
                className="reject-button"
                onClick={() => handleStatusUpdate('rejected')}
                disabled={loading}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faTimesCircle} />} 
                Reject
              </button>
            </>
          )}
{registration.status === 'approved' && (
            <button 
              className="reject-button"
              onClick={() => handleStatusUpdate('rejected')}
              disabled={loading}
            >
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faTimesCircle} />} 
              Revoke Approval
            </button>
          )}
          {registration.status === 'rejected' && (
            <button 
              className="approve-button"
              onClick={() => handleStatusUpdate('approved')}
              disabled={loading}
            >
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faCheckCircle} />} 
              Approve
            </button>
          )}
          <button 
            className="close-modal-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

// Main Component
const AuctionRegistrationList = () => {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    total: 0
  });
  useEffect(() => {
    fetchRegistrations();
  }, [filter, pagination.page]);

  const fetchRegistrations = async () => {
    try {
      setLoading(true);
      const status = filter !== 'all' ? filter : '';
      const token = localStorage.getItem('token');
      
      if (!token) {
        toast.error("Authentication token not found. Please login again.");
        return;
      }
      
      const response = await axios.get(`${BASE_URL}/api/v1/admin/auction-registrations`, {
        params: {
          status,
          page: pagination.page,
          limit: 10
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data.registrations);
      setRegistrations(response.data.registrations);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error("Error fetching registrations:", error);
      toast.error("Failed to load registration requests");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (registrationId, newStatus) => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        toast.error("Authentication token not found. Please login again.");
        return;
      }
      
      const response = await axios.put(
        `${BASE_URL}/api/v1/admin/auction-registration/${registrationId}`,
        { status: newStatus },
        { 
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      toast.success(`Registration ${newStatus} successfully`);
      
      // Update the local state
      setRegistrations(prev => 
        prev.map(reg => 
          reg._id === registrationId 
            ? { ...reg, status: newStatus } 
            : reg
        )
      );
      
      return response.data;
    } catch (error) {
      console.error("Error updating registration status:", error);
      toast.error("Failed to update registration status");
      throw error;
    }
  };
  const handleViewDetails = (registration) => {
    setSelectedRegistration(registration);
  };

  const handleCloseModal = () => {
    setSelectedRegistration(null);
  };

  const handleRefresh = () => {
    fetchRegistrations();
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case "approved":
        return <span className="status-badge approved"><FontAwesomeIcon icon={faCheckCircle} /> Approved</span>;
      case "rejected":
        return <span className="status-badge rejected"><FontAwesomeIcon icon={faTimesCircle} /> Rejected</span>;
      default:
        return <span className="status-badge pending"><FontAwesomeIcon icon={faHourglass} /> Pending</span>;
    }
  };

  const getCountByStatus = (status) => {
    return registrations.filter(reg => status === 'all' || reg.status === status).length;
  };
  return (
    <div className="admin-registrations-container">
      <div className="admin-registrations-header">
        <h1>Auction Registration Requests</h1>
        <div className="admin-registrations-actions">
          <div className="filter-container">
            <FontAwesomeIcon icon={faFilter} />
            <select 
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="status-filter"
            >
              <option value="all">All Requests</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
          <button 
            className="refresh-button"
            onClick={handleRefresh}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faSync} spin={loading} /> Refresh
          </button>
        </div>
      </div>

      <div className="admin-registrations-stats">
        <div className="stat-card">
          <div className="stat-value">{pagination.total}</div>
          <div className="stat-label">Total Requests</div>
        </div>
        <div className="stat-card pending">
          <div className="stat-value">{getCountByStatus('pending')}</div>
          <div className="stat-label">Pending</div>
        </div>
        <div className="stat-card approved">
          <div className="stat-value">{getCountByStatus('approved')}</div>
          <div className="stat-label">Approved</div>
        </div>
        <div className="stat-card rejected">
          <div className="stat-value">{getCountByStatus('rejected')}</div>
          <div className="stat-label">Rejected</div>
        </div>
      </div>
      {loading && registrations.length === 0 ? (
        <div className="loading-container">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
          <p>Loading registration requests...</p>
        </div>
      ) : registrations.length === 0 ? (
        <div className="no-registrations">
          <p>No registration requests found.</p>
        </div>
      ) : (
        <>
          <div className="admin-registrations-list">
            <table className="registrations-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Property</th>
                  <th>Bid Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {registrations.map((registration) => (
                  <tr key={registration._id}>
                    <td data-label="Date">
                      {new Date(registration.submittedAt).toLocaleDateString()}
                      <div className="submission-time">
                        {new Date(registration.submittedAt).toLocaleTimeString()}
                      </div>
                    </td>
                    <td data-label="User">
                      <div>{registration.buyerInfo?.firstName}</div>
                      <div className="user-email">{registration.buyerInfo?.email}</div>
                    </td>
                    <td data-label="Property">
                      {registration.auctionId?.street}, {registration.auctionId?.city}
                    </td>
                    <td data-label="Bid Amount">${registration.bidAmount.toLocaleString()}</td>
                    <td data-label="Status">{getStatusBadge(registration.status)}</td>
                    <td data-label="Actions">
                      <button 
                        className="view-details-button"
                        onClick={() => handleViewDetails(registration)}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          {pagination.pages > 1 && (
            <div className="pagination-controls">
              <button 
                onClick={() => setPagination(prev => ({ ...prev, page: prev.page - 1 }))}
                disabled={pagination.page === 1}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <div className="page-number">
                Page {pagination.page} of {pagination.pages}
              </div>
              <button
                onClick={() => setPagination(prev => ({ ...prev, page: prev.page + 1 }))}
                disabled={pagination.page === pagination.pages}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          )}
        </>
      )}

      {selectedRegistration && (
        <RegistrationDetailModal
          registration={selectedRegistration}
          onClose={handleCloseModal}
          onUpdateStatus={handleUpdateStatus}
        />
      )}
    </div>
  );
};

export default AuctionRegistrationList;