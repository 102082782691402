import React, { useState, useEffect } from 'react';
import AdminPanelHeader from '../adminPanelHeader/adminPanelHeader';
import AdminPanelAuctionList from '../adminPanelAuctionList/adminPanelAuctionList';
import AdminPanelSidebar from '../adminPanelSidebar/adminPanelSidebar';
import AuctionRegistrationList from '../AuctionRegistrationList/AuctionRegistrationList';
import AddUser from '../addUser/AddUser';
import ManageUser from '../manageUser/manageUser';
import { useNavigate } from 'react-router-dom';
import "./adminPanel.css";
import { useSelector } from 'react-redux';
import AddAuction from '../addAcution/AddAuction';
import { toast } from 'react-toastify';

function AdminPanel() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [mainContent, setMainContent] = useState("manangeAuction");
    const [loading, setLoading] = useState(true);
    
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    
    useEffect(() => {
        const checkAdminAccess = () => {
            // Check if user is authenticated
            if (!isAuthenticated) {
                navigate("/login", { state: { from: "/admin/dashboard" } });
                return;
            }
            
            // Check if user has admin role
            if (!user || user.role !== 'admin') {
                toast.error("Unauthorized: Admin access required");
                navigate("/");
                return;
            }
            
            setLoading(false);
        };
        
        checkAdminAccess();
    }, [isAuthenticated, user, navigate]);
    
    const toggleSidebar = (value) => {
        setIsSidebarOpen(value);
    };
    
    const handleMainContent = (value) => {
        setMainContent(value);
    };
    
    if (loading) {
        return (
            <div className="admin-panel-loading">
                <div className="spinner"></div>
                <p>Loading admin panel...</p>
            </div>
        );
    }
    
    return (
        <div className="admin-panel-container">
            <div className={`admin-panel-sidebar-content ${isSidebarOpen ? 'open' : ''}`}>
                <AdminPanelSidebar 
                    toggleSidebar={toggleSidebar} 
                    handleMainContent={handleMainContent} 
                />
            </div>
            <div className="admin-panel-main-content">
                <AdminPanelHeader toggleSidebar={toggleSidebar} />
                {mainContent === "manangeAuction" && <AdminPanelAuctionList />}
                {mainContent === "addUser" && <AddUser />}
                {mainContent === "manageUser" && <ManageUser />}
                {mainContent === "addAuction" && <AddAuction />}
                {mainContent === "manageRegistrations" && <AuctionRegistrationList />}
            </div>
        </div>
    );
}

export default AdminPanel;