import React, { useState } from 'react';
import './header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { clearUser } from '../../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from '../../../utils/api';
import { toast } from 'react-toastify';

const AdminPanelHeader = ({ toggleSidebar }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleImageClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  
  const handleLogout = async () => {
    try {
      await axios.get(`${BASE_URL}/api/v1/user/logout`);
      localStorage.removeItem('token');
      dispatch(clearUser());
      toast.success("Logged out successfully");
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error("Logout failed");
    }
  };

  return (
    <header className="admin-panel-header">
      <div className="admin-panel-search-bar">
        <FontAwesomeIcon 
          onClick={() => toggleSidebar(true)} 
          icon={faBars} 
          className="admin-panel-bar-icon fa-lg" 
        />
        <FontAwesomeIcon icon={faSearch} className="admin-panel-search-icon fa-lg" />
        <input type="text" placeholder="Search..." />
        <div className="admin-panel-user-info">
          <div className="admin-panel-avatar">
            <img 
              src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg" 
              alt="avatar" 
              onClick={handleImageClick} 
              className="clickable-avatar"
            />
          </div>
          {isDropdownVisible && (
            <div className="admin-name-dropdown">
              <button className="logout-button" onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} /> Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
export default AdminPanelHeader;