// src/components/ProtectedAdminRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, CircularProgress } from '@mui/material';

/**
 * A route wrapper that redirects non-admin users away from admin-only routes
 * Uses Redux loading state to wait for authentication to complete
 */
const ProtectAdminRoute = ({ children }) => {
  const { isAuthenticated, user, loading } = useSelector((state) => state.user);
  const location = useLocation();

  // If still loading authentication state, show loading spinner
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '85vh',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  // If not authenticated after checking, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }
  
  // If authenticated but not admin, redirect to home page with a warning
  if (user && user.role !== 'admin') {
    toast.error("Access denied: Admin privileges required");
    return <Navigate to="/" replace />;
  }
  
  // If authenticated and is admin, show the protected component
  return children;
};
export default ProtectAdminRoute;