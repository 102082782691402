// src/components/RegistrationForm.js
import React from 'react';
import './AddAuction.css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import BASE_URL from '../../../utils/api';
import axios from 'axios';
const AddAuction = () => {
    const [productName, setProductName] = useState('');
    const [auctionStartDate, setAuctionStartDate] = useState('');
    const [auctionStartTime, setAuctionStartTime] = useState('');
    const [auctionEndDate, setAuctionEndDate] = useState('');
    const [auctionEndTime, setAuctionEndTime] = useState('');
    const [reservePrice, setReservePrice] = useState('');
    const [minIncrement, setMinIncrement] = useState('');
    const [emd, setEmd] = useState('');
    const [commission, setCommission] = useState('');
    const [startBid, setStartBid] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [hot, setHot] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [beds, setBeds] = useState('');
    const [baths, setBaths] = useState('');
    const [lotSize, setLotSize] = useState('');
    const [yearBuilt, setYearBuilt] = useState('');
    const [hoaDues, setHoaDues] = useState('');
    const [apn, setApn] = useState('');
    const [squareFootage, setSquareFootage] = useState('');
    const [eventId, setEventId] = useState('');
    const [trusteeSaleNumber, setTrusteeSaleNumber] = useState('');
    const [image, setImage] = useState(null);
    const [otherImages, setOtherImages] = useState([]);
    const [userEmails, setuserEmails] = useState(['asad@gmail.com', 'xyz@gmail.com', 'abc@gmail.com', 'cde@gmail.com']);
    const [permittedEmails, setperMittedEmails] = useState([]);
    const [query, setQuery] = useState('');
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [url, setUrl] = useState('');
    const handleSearch = (e) => {
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        setFilteredEmails(userEmails.filter(email => email.toLowerCase().includes(searchQuery)));
    };
    const handleImageUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("upload_preset", "asaddemoapp");
        formData.append("cloud_name", "my1chatapp");
        try {
            const response = await axios.post(
                'https://api.cloudinary.com/v1_1/my1chatapp/image/upload',
                formData
            );
            console.log(response);
            setUrl(response.data.secure_url);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };
    const handleOtherImagesUpload = async (e) => {
        console.log(e.target.files.length);
        const uploadedUrls = []
        for (let i = 0; i < e.target.files.length; i++) {
            const formData = new FormData();
            formData.append("file", e.target.files[i]);
            formData.append("upload_preset", "asaddemoapp");
            formData.append("cloud_name", "my1chatapp");
            try {
                const response = await axios.post(
                    'https://api.cloudinary.com/v1_1/my1chatapp/image/upload',
                    formData
                );
                console.log(response);
                uploadedUrls.push(response.data.secure_url);
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
        setOtherImages(uploadedUrls);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!productName || !auctionStartDate || !auctionStartTime || !auctionEndDate || !auctionEndTime || !reservePrice || !minIncrement || !emd || !commission || !startBid || !propertyType || !hot || !street || !city || !county || !state || !zipCode || !beds || !baths || !squareFootage || !lotSize || !yearBuilt || !hoaDues || !apn || !eventId || !trusteeSaleNumber || !url) {
            toast.error("Please fill in all required fields.");
            return;
        }
        const auctionData = {
            productName,
            auctionStartDate,
            auctionStartTime,
            auctionEndDate,
            auctionEndTime,
            reservePrice,
            minIncrement,
            emd,
            commission,
            startBid,
            propertyDescription: `${propertyType} property located at ${street}, ${city}, ${state} - ${zipCode}`, // Customize this as needed
            propertyType,
            street,
            city,
            county,
            state,
            zipCode,
            beds,
            baths,
            squareFootage,
            lotSize,
            yearBuilt,
            monthlyHOADues: hoaDues,
            apn,
            eventID: eventId,
            trusteeSaleNumber,
            image: url,
            otherImages,
            onlineOrInPerson: hot,
            bidderEmails: permittedEmails,
        };
    
        try {
            const response = await axios.post(`${BASE_URL}/api/v1/product/create`, auctionData);
            console.log('Auction created successfully:', response.data);
            // Handle successful creation, e.g., clear the form or redirect
            toast.success("Auction created successfully");
        } catch (error) {
            console.error('Error creating auction:', error);
            // Handle error, e.g., show an error message
        }
    };
    
    return (
        <form onSubmit={handleSubmit} className="add-auction-registration-form">
            <div className='add-aution-container'>
                <h2>Add Product</h2>
                <div className="add-auction-form-group">
                    <label>Product Name</label>
                    <input
                        type="text"
                        placeholder="Product Name"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-row">
                    <div className="add-auction-form-group">
                        <label>Auction Start Date</label>
                        <input
                            type="date"
                            value={auctionStartDate}
                            onChange={(e) => setAuctionStartDate(e.target.value)}
                        />
                    </div>
                    <div className="add-auction-form-group">
                        <label>Auction Start Time</label>
                        <input
                            type="time"
                            value={auctionStartTime}
                            onChange={(e) => setAuctionStartTime(e.target.value)}
                        />
                    </div>
                </div>
                <div className="add-auction-form-row">
                    <div className="add-auction-form-group">
                        <label>Auction End Date</label>
                        <input
                            type="date"
                            value={auctionEndDate}
                            onChange={(e) => setAuctionEndDate(e.target.value)}
                        />
                    </div>
                    <div className="add-auction-form-group">
                        <label>Auction End Time</label>
                        <input
                            type="time"
                            value={auctionEndTime}
                            onChange={(e) => setAuctionEndTime(e.target.value)}
                        />
                    </div>
                </div>
                <div className="add-auction-form-group">
                    <label>Reserve Price</label>
                    <input
                        type="number"
                        placeholder="Enter Reserve Price"
                        value={reservePrice}
                        onChange={(e) => setReservePrice(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Min Increment</label>
                    <input
                        type="number"
                        placeholder="Enter Min Increment"
                        value={minIncrement}
                        onChange={(e) => setMinIncrement(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>EMD</label>
                    <input
                        type="number"
                        placeholder="Enter EMD"
                        value={emd}
                        onChange={(e) => setEmd(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Commission</label>
                    <input
                        type="text"
                        placeholder="Enter Commission in Percentage"
                        value={commission}
                        onChange={(e) => setCommission(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Start Bid</label>
                    <input
                        type="number"
                        placeholder="Start Bid"
                        value={startBid}
                        onChange={(e) => setStartBid(e.target.value)}
                    />
                </div>
                <h2>Property Description</h2>
                <div className="add-auction-form-row">
                    <div className="add-auction-form-group">
                        <label>Type</label>
                        <select value={propertyType} onChange={(e) => setPropertyType(e.target.value)}>
                            <option>Select</option>
                            <option value="Foreclosure Sale">Foreclosure Sales</option>
                            <option value="Reo Bank Owned">Reo Bank Owned</option>
                            <option value="Short Sale">Short Sales</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <div className="add-auction-form-group">
                        <label>Online / In Person</label>
                        <select value={hot} onChange={(e) => setHot(e.target.value)}>
                            <option>Select</option>
                            <option value="Online">Online</option>
                            <option value="In Person">In person</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                </div>
                <div className="add-auction-form-row">
                    <div className="add-auction-form-group">
                        <label>Street</label>
                        <input
                            type="text"
                            placeholder="Enter Street"
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                        />
                    </div>
                    <div className="add-auction-form-group">
                        <label>City</label>
                        <input
                            type="text"
                            placeholder="Enter City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                </div>
                <div className="add-auction-form-row">
                    <div className="add-auction-form-group">
                        <label>County</label>
                        <input
                            type="text"
                            placeholder="Enter County"
                            value={county}
                            onChange={(e) => setCounty(e.target.value)}
                        />
                    </div>
                    <div className="add-auction-form-group">
                        <label>State</label>
                        <input
                            type="text"
                            placeholder="Enter State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </div>
                    <div className="add-auction-form-group">
                        <label>Zip Code</label>
                        <input
                            type="text"
                            placeholder="Enter Zip Code"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                        />
                    </div>
                </div>
                <div className="add-auction-form-row">
                    <div className="add-auction-form-group">
                        <label>Beds</label>
                        <input
                            type="number"
                            placeholder="Enter Number Of Beds"
                            value={beds}
                            onChange={(e) => setBeds(e.target.value)}
                        />
                    </div>
                    <div className="add-auction-form-group">
                        <label>Baths</label>
                        <input
                            type="number"
                            placeholder="Enter Number Of Baths"
                            value={baths}
                            onChange={(e) => setBaths(e.target.value)}
                        />
                    </div>
                </div>
                <div className="add-auction-form-group">
                    <label>Square Footage</label>
                    <input
                        type="text"
                        placeholder="Enter Square Footage"
                        value={squareFootage}
                        onChange={(e) => setSquareFootage(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Lot Size (Acres)</label>
                    <input
                        type="text"
                        placeholder="Enter Lot Size"
                        value={lotSize}
                        onChange={(e) => setLotSize(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Year Built</label>
                    <input
                        type="number"
                        placeholder="Enter Year Built"
                        value={yearBuilt}
                        onChange={(e) => setYearBuilt(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Monthly HOA Dues</label>
                    <input
                        type="text"
                        placeholder="Enter Monthly HOA Dues"
                        value={hoaDues}
                        onChange={(e) => setHoaDues(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>APN</label>
                    <input
                        type="text"
                        placeholder="Enter APN"
                        value={apn}
                        onChange={(e) => setApn(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Event ID</label>
                    <input
                        type="text"
                        placeholder="Enter Event ID"
                        value={eventId}
                        onChange={(e) => setEventId(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Trustee Sale Number</label>
                    <input
                        type="text"
                        placeholder="Enter Trustee Sale Number"
                        value={trusteeSaleNumber}
                        onChange={(e) => setTrusteeSaleNumber(e.target.value)}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Image</label>
                    <input
                        type="file"
                        onChange={handleImageUpload}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Other Images</label>
                    <input
                        type="file"
                        multiple
                        onChange={handleOtherImagesUpload}
                    />
                </div>
                <div className="add-auction-form-group">
                    <label>Bidder Permission</label>
                    <div className='biider-permission-container'>
                        {permittedEmails.map((permittedEmail, index) => {
                            return (
                                <div key={index} className='permitted-email'>
                                    <FontAwesomeIcon onClick={() => setperMittedEmails(permittedEmails.filter((email) => email !== permittedEmail))} icon={faX} className='email-removal-icon' />
                                    {permittedEmail}
                                </div>
                            )
                        })}
                    </div>
                    <input
                        type="text"
                        placeholder="Search Email"
                        value={query}
                        onChange={handleSearch}
                    />
                    <ul className='bidder-email-list'>
                        {query !== "" && filteredEmails.map(email => (
                            <li onClick={() => setperMittedEmails([email, ...permittedEmails])} key={email} value={email}>{email}</li>
                        ))}
                    </ul>
                </div>
                <div className='add-auction-submit-button-container'>
                    <button type='submit' className="add-auction-submit-button">Create</button>
                </div>
            </div>
        </form>
    );
};

export default AddAuction;