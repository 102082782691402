import React from "react";
import PropTypes from "prop-types";
import "./ContactUsModal.css";
import axios from "axios";
import BASE_URL from "../../../utils/api";
const CrosssClose = () => {
  return (
    <svg
      data-v-265892a8=""
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        data-v-265892a8=""
        x1="28.3536"
        y1="1.35355"
        x2="0.353553"
        y2="29.3536"
        stroke="grey"
      ></line>
      <line
        data-v-265892a8=""
        x1="0.353553"
        y1="0.646447"
        x2="28.3536"
        y2="28.6464"
        stroke="grey"
      ></line>
    </svg>
  );
};
import { useEffect } from "react";
import { gsap } from 'gsap';
const ContactUsModal = ({ isOpen, closeModal,setIsModalOpen }) => {
  console.log(closeModal);
  const handleClick=()=>{
         console.log("clicked cross");
        closeModal();
  }
  useEffect(() => {
    console.log("in footer contact us modal");
    const modal = document.querySelector('.contact-us-modal');
    if (modal) {
      if (isOpen) {
        gsap.fromTo(modal, { y: '-100%' }, { y: '0%',visibility:"visible",duration: 0.3, ease: 'power3.out' });
      } else {
        gsap.to(modal, { y: '-100%', duration: .3,ease: 'power3.in',onComplete:closeModal });
      }
    }  
  }, [isOpen]);
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("access_key", "9e60b541-0f85-4d9c-9ed8-b7a5f7e723d3");
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);
    console.log(object, formData);
    try {
      const response = await axios.post(`${BASE_URL}/api/saveContact`, object, {
        headers: { "Content-Type": "application/json" },
      });
      console.log("Local response", response);
    } catch (error) {
      console.error("Local request error", error);
    }
    try {
      const res = await axios.post("https://api.web3forms.com/submit", json, {
        headers: { "Content-Type": "application/json", Accept: "application/json" },
      });
      if (res.data.success) {
        console.log("Web3Forms Success", res.data);
        // Optionally reset the form here
        event.target.reset();
      } else {
        console.error("Web3Forms error", res.data);
      }
    } catch (error) {
      console.errorM("Web3Forms request error", error);
    }
  };
  return (
    <div className='contact-us-modal'>
      <div className="contact-us-modal-content">
        <h3>Contact Us</h3>
        <p className="contact-us-get-in-touch">
          Tell us a bit about yourself and we&apos;ll get in touch within one
          business day
          <span className="contact-us-modal-close" onClick={()=>setIsModalOpen(false)}>
          <CrosssClose />
        </span>
        </p>
        <form onSubmit={onSubmit} >
          <div className="contact-us-modal-row">
            <div className="contact-us-modal-col">
              <label>
                First Name:
                <span className="contact-us-modal-required">*</span>
              </label>
              <input type="text" name="firstName" required className="contactus-modal-input" />
            </div>
            <div className="contact-us-modal-col">
              <label>
                Last Name:
                <span className="contact-us-modal-required">*</span>
              </label>
              <input type="text" name="lastName" required className="contactus-modal-input" />
            </div>
          </div>
          <div className="contact-us-modal-row">
            <div className="contact-us-modal-col">
              <label>Company Name:</label>
              <input type="text" name="companyName" className="contactus-modal-input" />
            </div>
          </div>
          <div className="contact-us-modal-row">
            <div className="contact-us-modal-col">
              <label>
                Email:
                <span className="contact-us-modal-required">*</span>
              </label>
              <input type="email" name="email" required className="contactus-modal-input" />
            </div>
            <div className="contact-us-modal-col">
              <label>
                Phone:
                <span className="contact-us-modal-required">*</span>
              </label>
              <input type="tel" name="phone" required className="contactus-modal-input" />
            </div>
          </div>
          <div className="contact-us-modal-row">
            <div className="contact-us-modal-col">
              <label>Let us know how we can help you:</label>
              <textarea name="message" className="contactus-modal-input"></textarea>
            </div>
          </div>
          <div className="contact-us-modal-row">
            <div className="contact-us-modal-col" style={{marginTop:"0rem",marginBottom:"0rem"}}>
              <p className="contact-us-modal-disclaimer">
                By submitting this form you are agreeing to Vihara&apos;s{" "}
                <a href="/website-terms-of-use">terms of service</a> and{" "}
                <a href="/privacy-statement">privacy policy</a>. Contact{" "}
                <a href="mailto:trisha@vihara.ai" className="trisha-email-link">trisha@vihara.ai</a> with
                any questions.
              </p>
              <p className="contact-us-modal-disclaimer">
                I agree that Vihara may contact me about their products and
                services.
              </p>
            </div>
          </div>
          <button type="submit" className="contact-us-button-submit">
            Submit
          </button>
          <p className="contact-us-get-support">
            Need support?{" "}
            <a href="mailto:trisha@vihara.ai">Go here instead.</a>
          </p>
        </form>
      </div>
    </div>
  );
};

ContactUsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ContactUsModal;