// LegalDisclosuresStep.jsx
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const LegalDisclosuresStep = ({ formData, handleInputChange }) => {
  return (
    <div className="auction-registration-form-step-content">
      <div className="auction-registration-form-section">
        <div className="auction-registration-form-section-title">LEGAL DISCLOSURES</div>
        
        <div className="auction-registration-form-disclosures">
          {/* Terms and Conditions */}
          <div className="auction-registration-form-disclosure-item">
            <label className="auction-registration-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.agreeToTerms}
                onChange={(e) => handleInputChange('agreeToTerms', e.target.checked)}
                className="auction-registration-form-checkbox"
              />
              <span>Terms and Conditions of Sale <FontAwesomeIcon icon={faInfoCircle} className="auction-registration-form-info-icon" /></span>
            </label>
            <div className="auction-registration-form-disclosure-text">
              I acknowledge that I have read, understand, and agree to all Terms and Conditions 
              of Sale for this property auction. I understand this creates a legally binding 
              agreement.
            </div>
          </div>

          {/* Property Inspection */}
          <div className="auction-registration-form-disclosure-item">
            <label className="auction-registration-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.acknowledgeInspection}
                onChange={(e) => handleInputChange('acknowledgeInspection', e.target.checked)}
                className="auction-registration-form-checkbox"
              />
              <span>Property Inspection Acknowledgment <FontAwesomeIcon icon={faInfoCircle} className="auction-registration-form-info-icon" /></span>
            </label>
            <div className="auction-registration-form-disclosure-text">
              I understand that I am responsible for conducting my own due diligence and property 
              inspection. The property is being sold "AS-IS, WHERE-IS" with no warranties expressed 
              or implied.
            </div>
          </div>

          {/* Financing Requirements */}
          <div className="auction-registration-form-disclosure-item">
            <label className="auction-registration-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.understandFinancing}
                onChange={(e) => handleInputChange('understandFinancing', e.target.checked)}
                className="auction-registration-form-checkbox"
              />
              <span>Financing Requirements <FontAwesomeIcon icon={faInfoCircle} className="auction-registration-form-info-icon" /></span>
            </label>
            <div className="auction-registration-form-disclosure-text">
              I understand and accept the financing requirements for this property. I will provide 
              proof of funds or a pre-approval letter within 48 hours of bid acceptance if required.
            </div>
          </div>

          {/* Property Condition */}
          <div className="auction-registration-form-disclosure-item">
            <label className="auction-registration-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.acceptPropertyCondition}
                onChange={(e) => handleInputChange('acceptPropertyCondition', e.target.checked)}
                className="auction-registration-form-checkbox"
              />
              <span>Property Condition Acceptance <FontAwesomeIcon icon={faInfoCircle} className="auction-registration-form-info-icon" /></span>
            </label>
            <div className="auction-registration-form-disclosure-text">
              I accept the current condition of the property and understand that I am responsible 
              for any repairs, maintenance, or improvements after purchase.
            </div>
          </div>

          {/* Closing Timeline */}
          <div className="auction-registration-form-disclosure-item">
            <label className="auction-registration-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.acknowledgeClosingTimeline}
                onChange={(e) => handleInputChange('acknowledgeClosingTimeline', e.target.checked)}
                className="auction-registration-form-checkbox"
              />
              <span>Closing Timeline Acknowledgment <FontAwesomeIcon icon={faInfoCircle} className="auction-registration-form-info-icon" /></span>
            </label>
            <div className="auction-registration-form-disclosure-text">
              I understand and agree to the required closing timeline and acknowledge that failure 
              to close within the specified timeframe may result in forfeiture of earnest money 
              deposit.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalDisclosuresStep;