// ContractStep.jsx
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const ContractStep = ({ 
  formData, 
  handleInputChange, 
  product, 
  currentBid, 
  buyersPremium 
}) => {
  const totalPurchasePrice = currentBid + buyersPremium;
  const earnestMoney = Math.round(totalPurchasePrice * 0.05); // 5% earnest money

  return (
    <div className="auction-registration-form-step-content">
      <div className="auction-registration-form-section">
        <div className="auction-registration-form-section-title">CONTRACT CONFIRMATION</div>

        {/* Important Notice */}
        <div className="auction-registration-form-contract-notice">
          <h4><FontAwesomeIcon icon={faExclamationTriangle} className="auction-registration-form-warning-icon" /> Important Notice</h4>
          <p>
            By proceeding with this submission, you are entering into a legally binding agreement 
            to purchase this property. Please review all information carefully before proceeding.
          </p>
        </div>

        {/* Purchase Agreement Summary */}
        <div className="auction-registration-form-contract-summary">
          <h3>Purchase Agreement Summary</h3>
          <div className="auction-registration-form-contract-details">
            <div className="auction-registration-form-review-item">
              <span className="auction-registration-form-review-label">Property Address:</span>
              <span className="auction-registration-form-review-value">{product?.street}, {product?.city}, {product?.state}</span>
            </div>
            <div className="auction-registration-form-review-item">
              <span className="auction-registration-form-review-label">Purchase Price:</span>
              <span className="auction-registration-form-review-value">${currentBid.toLocaleString()}</span>
            </div>
            <div className="auction-registration-form-review-item">
              <span className="auction-registration-form-review-label">Buyer's Premium:</span>
              <span className="auction-registration-form-review-value">${buyersPremium.toLocaleString()}</span>
            </div>
            <div className="auction-registration-form-review-item">
              <span className="auction-registration-form-review-label">Total Purchase Price:</span>
              <span className="auction-registration-form-review-value">${totalPurchasePrice.toLocaleString()}</span>
            </div>
            <div className="auction-registration-form-review-item">
              <span className="auction-registration-form-review-label">Required Earnest Money:</span>
              <span className="auction-registration-form-review-value">${earnestMoney.toLocaleString()}</span>
            </div>
          </div>
        </div>

        {/* Contract Terms */}
        <div className="auction-registration-form-contract-terms">
          <h3>Terms & Deadlines</h3>
          <ul>
            <li>Earnest Money Deposit must be submitted within 24 hours of bid acceptance</li>
            <li>Proof of funds or pre-approval letter required within 48 hours</li>
            <li>Property inspection period: 10 calendar days from acceptance</li>
            <li>Closing must occur within 30 calendar days</li>
            <li>Failure to meet deadlines may result in forfeiture of earnest money</li>
          </ul>
        </div>

        {/* Final Agreements */}
        <div className="auction-registration-form-contract-agreements">
          <div className="auction-registration-form-disclosure-item">
            <label className="auction-registration-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.agreeToContract}
                onChange={(e) => handleInputChange('agreeToContract', e.target.checked)}
                className="auction-registration-form-checkbox"
              />
              <span>
                I have read and agree to the Purchase Agreement terms and conditions
              </span>
            </label>
          </div>

          <div className="auction-registration-form-disclosure-item">
            <label className="auction-registration-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.agreeToDeposit}
                onChange={(e) => handleInputChange('agreeToDeposit', e.target.checked)}
                className="auction-registration-form-checkbox"
              />
              <span>
                I agree to submit the required earnest money deposit of ${earnestMoney.toLocaleString()}{" "}
                 within 24 hours of bid acceptance
              </span>
            </label>
          </div>

          <div className="auction-registration-form-disclosure-item">
            <label className="auction-registration-form-checkbox-label">
              <input
                type="checkbox"
                checked={formData.acceptTerms}
                onChange={(e) => handleInputChange('acceptTerms', e.target.checked)}
                className="auction-registration-form-checkbox"
              />
              <span>
                I understand that this is a legally binding agreement and accept all terms and conditions
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractStep;