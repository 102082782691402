import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({ size = 'medium', message }) => {
  const spinnerSizeClass = {
    small: 'spinner-small',
    medium: 'spinner-medium',
    large: 'spinner-large',
  }[size] || 'spinner-medium';

  return (
    <div className="loading-spinner-container">
      <div className={`loading-spinner ${spinnerSizeClass}`}></div>
      {message && <p className="loading-message">{message}</p>}
    </div>
  );
};

export default LoadingSpinner;