import React, { useState } from 'react';
import './AddUser.css';
import axios from 'axios';
import BASE_URL from '../../../utils/api';
import { toast } from 'react-toastify';

function AddUser() {
  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    businessPhone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    role: 'user',
    userType: 'Buyer'
  });
  
  const [loading, setLoading] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    
    if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    }
    
    setLoading(true);
    
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');
      
      if (!token) {
        toast.error("Authentication token not found. Please login again.");
        return;
      }
      
      // Prepare user data, excluding confirmPassword
      const userData = { ...formData };
      delete userData.confirmPassword;
      
      // Make API call to create user
      const response = await axios.post(
        `${BASE_URL}/api/v1/admin/register`, 
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      toast.success("User created successfully!");
      
      // Reset form after successful submission
      setFormData({
        name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        businessPhone: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        role: 'user',
        userType: 'Buyer'
      });
      
    } catch (error) {
      console.error("Error creating user:", error);
      const errorMessage = error.response?.data?.message || "Failed to create user";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="admin-panel-user-form-container">
      <div className='admin-panel-user-container'>
        <h2>Create User</h2>
        <form onSubmit={handleSubmit}>
          <div className="admin-panel-user-form-row">
            <div className="admin-panel-user-form-group">
              <label>First Name</label>
              <input 
                type="text" 
                name="name" 
                placeholder="First Name" 
                value={formData.name} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="admin-panel-user-form-group">
              <label>Last Name</label>
              <input 
                type="text" 
                name="last_name" 
                placeholder="Last Name" 
                value={formData.last_name} 
                onChange={handleChange} 
                required 
              />
            </div>
          </div>
          
          <div className="admin-panel-user-form-row">
            <div className="admin-panel-user-form-group">
              <label>Email</label>
              <input 
                type="email" 
                name="email" 
                placeholder="Email" 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="admin-panel-user-form-group">
              <label>Password</label>
              <input 
                type="password" 
                name="password" 
                placeholder="Password" 
                value={formData.password} 
                onChange={handleChange} 
                required 
              />
            </div>
          </div>
          
          <div className="admin-panel-user-form-row">
            <div className="admin-panel-user-form-group">
              <label>Confirm Password</label>
              <input 
                type="password" 
                name="confirmPassword" 
                placeholder="Confirm Password" 
                value={formData.confirmPassword} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="admin-panel-user-form-group">
              <label>Business Phone (Optional)</label>
              <input 
                type="text" 
                name="businessPhone" 
                placeholder="Business Phone (Optional)" 
                value={formData.businessPhone} 
                onChange={handleChange} 
              />
            </div>
          </div>
          
          <div className="admin-panel-user-form-row">
            <div className="admin-panel-user-form-group">
              <label>Address (Optional)</label>
              <input 
                type="text" 
                name="address" 
                placeholder="Address (Optional)" 
                value={formData.address} 
                onChange={handleChange} 
              />
            </div>
            <div className="admin-panel-user-form-group">
              <label>City (Optional)</label>
              <input 
                type="text" 
                name="city" 
                placeholder="City (Optional)" 
                value={formData.city} 
                onChange={handleChange} 
              />
            </div>
          </div>
          
          <div className="admin-panel-user-form-row">
            <div className="admin-panel-user-form-group">
              <label>State (Optional)</label>
              <input 
                type="text" 
                name="state" 
                placeholder="State" 
                value={formData.state} 
                onChange={handleChange} 
              />
            </div>
            <div className="admin-panel-user-form-group">
              <label>ZIP (Optional)</label>
              <input 
                type="text" 
                name="zipCode" 
                placeholder="Zip" 
                value={formData.zipCode} 
                onChange={handleChange} 
              />
            </div>
          </div>
          
          <div className="admin-panel-user-form-row">
            <div className="admin-panel-user-form-group">
              <label>Country</label>
              <input 
                type="text" 
                name="country" 
                placeholder="Country" 
                value={formData.country} 
                onChange={handleChange}
              />
            </div>
            <div className="admin-panel-user-form-group">
              <label>Role</label>
              <select 
                name="role" 
                value={formData.role} 
                onChange={handleChange}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </div>
          
          <div className="admin-panel-user-form-row">
            <div className="admin-panel-user-form-group">
              <label>User Type</label>
              <select 
                name="userType" 
                value={formData.userType} 
                onChange={handleChange}
              >
                <option value="Buyer">Buyer</option>
                <option value="Agent">Agent</option>
              </select>
            </div>
          </div>
          
          <div className='admin-panel-create-user-button'>
            <button type="submit" disabled={loading}>
              {loading ? 'Creating...' : 'Create User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddUser;