import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import axios from 'axios';
import './LiveAuctionRoom.css';
import { FaExclamationTriangle, FaCheckCircle, FaClock, FaUsers, FaArrowLeft, FaBolt } from 'react-icons/fa';
import BASE_URL from "../../utils/api";
import NavBackground from '../includes/navBackground';
import LoadingSpinner from '../Common/LoadingSpinner';
import PropertyDetails from './PropertyDetails';

const LiveAuctionRoom = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const product = useSelector((state) => 
    state.property.Properties.find(p => p._id === id)
  );
  
  // Socket and connection state
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [hasAccess, setHasAccess] = useState(false);
  
  // Auction state
  const [currentBid, setCurrentBid] = useState(0);
  const [currentBidder, setCurrentBidder] = useState(null);
  const [bidAmount, setBidAmount] = useState("");
  const [bidHistory, setBidHistory] = useState([]);
  const [participants, setParticipants] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);
  const [auctionExtensionCount, setAuctionExtensionCount] = useState(0);
  const [auctionEndTime, setAuctionEndTime] = useState(null);
  const [auctionStatus, setAuctionStatus] = useState("active"); // Default to active, not upcoming
  
  // Auto-bidding state
  const [isAutoBidding, setIsAutoBidding] = useState(false);
  const [maxAutoBidAmount, setMaxAutoBidAmount] = useState("");
  const [autoBidIncrement, setAutoBidIncrement] = useState(1000);
  const [minAutoBidAmount, setMinAutoBidAmount] = useState(0);
  const [minManualBid, setMinManualBid] = useState(0);
  
  // UI state
  const [bidError, setBidError] = useState("");
  const [autoBidError, setAutoBidError] = useState("");
  const [isProcessingBid, setIsProcessingBid] = useState(false);
  const [notification, setNotification] = useState(null);
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  
  // Refs to maintain values in callbacks
  const socketRef = useRef(null);
  const timeLeftRef = useRef(null);
  const bidHistoryRef = useRef([]);
  const bidAmountRef = useRef("");
  const auctionEndTimeRef = useRef(null);

  // Calculate time remaining until auction end
  const calculateTimeRemaining = useCallback(() => {
    if (!product) return null;

    const now = new Date();
    
    // Handle the end time calculation properly
    let endTime;
    
    // First check if we have a cached end time from socket updates
    if (auctionEndTimeRef.current) {
      endTime = auctionEndTimeRef.current;
    } 
    // Then check for auctionEndTime in the product
    else if (product.auctionEndTime) {
      // If auctionEndTime is a full date string
      if (product.auctionEndTime.includes('T')) {
        endTime = new Date(product.auctionEndTime);
      } 
      // If auctionEndTime is just a time string (like "17:00")
      else {
        endTime = new Date(product.auctionEndDate);
        const timeParts = product.auctionEndTime.split(':');
        if (timeParts.length >= 2) {
          endTime.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), 0, 0);
        }
      }
    } 
    // Default to the auctionEndDate
    else {
      endTime = new Date(product.auctionEndDate);
    }
    
    console.log('Calculated end time:', endTime);
    
    // If auction has ended
    if (now > endTime) {
      return 0;
    }

    // Return remaining seconds
    return Math.floor((endTime - now) / 1000);
  }, [product]);

  // Format time for display
  const formatTime = (seconds) => {
    if (seconds === null || isNaN(seconds)) return "--:--:--";
    if (seconds <= 0) return "00:00:00";
    
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    return `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  // Show notification
  const showNotification = (type, title, message) => {
    //setNotification({ type, title, message });
    setTimeout(() => setNotification(null), 1000);
  };

  // Handle bid submission
  const handleBidSubmit = (e) => {
    e.preventDefault();
    
    // Validate bid
    const amount = parseFloat(bidAmount);
    if (isNaN(amount)) {
      setBidError("Please enter a valid bid amount");
      return;
    }
    
    if (amount <= currentBid) {
      setBidError(`Bid must be higher than current bid (₹${currentBid.toLocaleString()})`);
      return;
    }
    
    if (minManualBid > 0 && amount < minManualBid) {
      setBidError(`Minimum bid required is ₹${minManualBid.toLocaleString()}`);
      return;
    }
    
    if (!socket || !socket.connected) {
      setBidError("Not connected to auction server");
      return;
    }
    
    // Prevent double submission
    if (isProcessingBid) {
      return;
    }
    
    setIsProcessingBid(true);
    
    // Send bid to server
    socket.emit('place-bid', {
      auctionId: id,
      bidAmount: amount
    });
    
    // Clear bid input
    setBidAmount("");
    bidAmountRef.current = "";
  };

  // Handle auto-bidding toggle
  const toggleAutoBidding = async () => {
    try {
      // Clear previous errors
      setAutoBidError("");
      
      // Disable toggle if already enabled
      if (isAutoBidding) {
        setAutoBidError("Auto-bidding cannot be disabled once activated");
        return;
      }
      
      // Validate max amount
      const maxAmount = parseFloat(maxAutoBidAmount);
      if (isNaN(maxAmount) || maxAmount <= currentBid) {
        setAutoBidError("Please enter a maximum bid amount higher than the current bid");
        return;
      }
      
      // Check minimum auto-bid amount
      if (minAutoBidAmount > 0 && maxAmount < minAutoBidAmount) {
        setAutoBidError(`Minimum auto-bid amount required is ₹${minAutoBidAmount.toLocaleString()}`);
        return;
      }
      
      // Save auto-bidding settings
      const response = await axios.post(`${BASE_URL}/api/bidding/auto-bidding/settings`, {
        auctionId: id,
        enabled: true,
        maxAmount: maxAmount,
        increment: parseInt(autoBidIncrement) || 1000
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.data.success) {
        setIsAutoBidding(true);
        setAutoBidError("");
        showNotification("success", "Auto-Bidding Activated", "Your auto-bidding has been successfully activated.");
        
        // If an initial bid was placed, update the UI
        if (response.data.data && response.data.data.initialBid) {
          showNotification("success", "Initial Bid Placed", `Your initial auto-bid of ₹${response.data.data.initialBid.toLocaleString()} was placed.`);
        }
      } else {
        setAutoBidError(response.data.error || "Failed to enable auto-bidding");
      }
    } catch (error) {
      console.error('Error setting up auto-bidding:', error);
      setAutoBidError(error.response?.data?.error || "Failed to save auto-bidding settings");
    }
  };

  // Handle suggested increment click
  const handleIncrementClick = (increment) => {
    const newBid = currentBid + increment;
    setBidAmount(newBid.toString());
    bidAmountRef.current = newBid.toString();
  };

  // Navigate back to auctions page
  const handleBackClick = () => {
    navigate('/auctions');
  };

  // Initialize socket connection
  useEffect(() => {
    const checkAccess = async () => {
      try {
        if (!id) {
          setError("Property ID is required");
          setLoading(false);
          return;
        }
        // Check if user has access to this auction
        const response = await axios.get(`${BASE_URL}/api/bidding/auction/check-access/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.data.success) {
          setError(response.data.error || "You don't have access to this auction");
          setHasAccess(false);
        } else {
          setHasAccess(true);
          
          // Set initial auction end time
          if (product) {
            let endTime;
            
            // If auctionEndTime is a full date string
            if (product.auctionEndTime && product.auctionEndTime.includes('T')) {
              endTime = new Date(product.auctionEndTime);
            } 
            // If auctionEndTime is just a time string (like "17:00")
            else if (product.auctionEndTime) {
              endTime = new Date(product.auctionEndDate);
              const timeParts = product.auctionEndTime.split(':');
              if (timeParts.length >= 2) {
                endTime.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), 0, 0);
              }
            } 
            // Default to the end date
            else {
              endTime = new Date(product.auctionEndDate);
            }
            
            console.log('Initial end time set to:', endTime);
            
            setAuctionEndTime(endTime);
            auctionEndTimeRef.current = endTime;
            
            // Set extension count if available
            if (product.auctionExtensionCount) {
              setAuctionExtensionCount(product.auctionExtensionCount || 0);
            }
            
            // Set initial status based on end time
            const now = new Date();
            if (now > endTime) {
              setAuctionStatus("ended");
            } else {
              // No more checking for 7 hours - just active if not ended
              setAuctionStatus("active");
            }
          }
          
          // Initialize socket connection
          const newSocket = io(BASE_URL, {
            auth: { token: localStorage.getItem('token') },
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000
          });
          
          newSocket.on('connect', () => {
            console.log('Connected to auction server');
            setConnected(true);
            socketRef.current = newSocket;
            
            // Join auction room
            newSocket.emit('join-auction', id);
          });
          
          newSocket.on('connect_error', (err) => {
            console.error('Connection error:', err);
            setError(`Connection error: ${err.message}`);
            setConnected(false);
          });
          
          newSocket.on('reconnect', (attempt) => {
            console.log(`Reconnected after ${attempt} attempts`);
            setConnected(true);
            setError("");
            
            // Rejoin auction room
            newSocket.emit('join-auction', id);
          });
          
          newSocket.on('reconnect_error', (err) => {
            console.error('Reconnection error:', err);
            setError(`Reconnection error: ${err.message}`);
          });
          
          newSocket.on('reconnect_failed', () => {
            console.error('Failed to reconnect');
            setError("Connection lost. Please refresh the page.");
          });
          
          setSocket(newSocket);
        }
      } catch (error) {
        console.error('Access check error:', error);
        setError(error.response?.data?.error || "Error checking auction access");
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
    
    // Cleanup function
    return () => {
      if (socketRef.current) {
        console.log('Disconnecting socket...');
        socketRef.current.disconnect();
      }
    };
  }, [id, product]);

  // Socket event listeners
  useEffect(() => {
    if (!socket) return;
    // Auction status update
    socket.on('auction-status', (status) => {
      console.log('Received auction status:', status);
      setCurrentBid(status.currentBid || 0);
      setCurrentBidder(status.currentBidder);
      setParticipants(status.participants);
      
      if (status.recentBids && status.recentBids.length > 0) {
        setBidHistory(status.recentBids);
        bidHistoryRef.current = status.recentBids;
      }
      
      // Set minimum bids based on current bid
      setMinManualBid(Math.max(minManualBid, status.currentBid + 1000));

      // Set auction status
      if (status.auctionStatus) {
        setAuctionStatus(status.auctionStatus);
      }
      
      // Set auction end time if available
      if (status.endTime) {
        const endTime = new Date(status.endTime);
        setAuctionEndTime(endTime);
        auctionEndTimeRef.current = endTime;
      }
    });
    
    // Minimum bid update
    socket.on('min-bid-update', (data) => {
      console.log('Minimum bid update:', data);
      if (data.minManualBid) setMinManualBid(data.minManualBid);
      if (data.minAutoBidAmount) setMinAutoBidAmount(data.minAutoBidAmount);
    });
    
    // Participant update
    socket.on('participant-update', (data) => {
      setParticipants(data.count);
    });
    
    // Bid update
    socket.on('bid-update', (update) => {
      console.log('Bid update received:', update);
      setCurrentBid(update.currentBid);
      setCurrentBidder(update.currentBidder);
      
      // Update bid history
      const updatedHistory = [update, ...bidHistoryRef.current].slice(0, 50);
      setBidHistory(updatedHistory);
      bidHistoryRef.current = updatedHistory;
      
      // Clear any bid error
      setBidError('');
      setIsProcessingBid(false);

      // Show notification if it's your bid
      if (update.currentBidder?.id === user._id) {
        showNotification("success", "Bid Placed", `Your bid of ₹${update.currentBid.toLocaleString()} was successful.`);
      } else {
        showNotification("warning", "New Bid", `A new bid of ₹${update.currentBid.toLocaleString()} was placed.`);
      }
    });
    
    // Timer update
    socket.on('timer-update', (remainingTime) => {
      setTimeLeft(remainingTime);
      timeLeftRef.current = remainingTime;
      
      // Update auction status based on time
      if (remainingTime <= 0) {
        setAuctionStatus("ended");
      } else {
        setAuctionStatus("active"); // No more "upcoming" status - just active or ended
      }
    });
    
    // Auction extension
    socket.on('auction-extended', (data) => {
      console.log('Auction extended:', data);
      const newEndTime = new Date(data.endTime);
      setAuctionEndTime(newEndTime);
      auctionEndTimeRef.current = newEndTime;
      setAuctionExtensionCount(data.extensionCount || (auctionExtensionCount + 1));
      showNotification("warning", "Auction Extended", "The auction has been extended due to last-minute bidding.");
    });
    
    // Bid error
    socket.on('bid-error', (errorMessage) => {
      console.error('Bid error:', errorMessage);
      setBidError(errorMessage);
      setIsProcessingBid(false);
      showNotification("error", "Bid Error", errorMessage);
    });
    
    // Auction error
    socket.on('auction-error', (errorMessage) => {
      console.error('Auction error:', errorMessage);
      setError(errorMessage);
      showNotification("error", "Auction Error", errorMessage);
    });
    
    return () => {
      socket.off('auction-status');
      socket.off('min-bid-update');
      socket.off('participant-update');
      socket.off('bid-update');
      socket.off('timer-update');
      socket.off('auction-extended');
      socket.off('bid-error');
      socket.off('auction-error');
    };
  }, [socket, minManualBid, user?._id, auctionExtensionCount]);

  // Timer effect
  useEffect(() => {
    if (!hasAccess || !product) return;
    
    // Initialize timer
    setTimeLeft(calculateTimeRemaining());
    timeLeftRef.current = calculateTimeRemaining();
    
    // Determine initial auction status based on time
    const timeRemaining = calculateTimeRemaining();
    if (timeRemaining <= 0) {
      setAuctionStatus("ended");
    } else {
      setAuctionStatus("active"); // No more "upcoming" status
    }
    
    // Update timer every second
    const timerInterval = setInterval(() => {
      const remaining = calculateTimeRemaining();
      setTimeLeft(remaining);
      timeLeftRef.current = remaining;
      
      // Update auction status based on time
      if (remaining <= 0) {
        setAuctionStatus("ended");
      } else {
        setAuctionStatus("active");
      }
      
      // Emit timer update to server
      if (socketRef.current && socketRef.current.connected) {
        socketRef.current.emit('auction-timer', {
          auctionId: id,
          timeLeft: remaining
        });
      }
      
      // End auction if time is up
      if (remaining <= 0) {
        clearInterval(timerInterval);
      }
    }, 1000);
    
    return () => clearInterval(timerInterval);
  }, [hasAccess, product, auctionEndTime, calculateTimeRemaining, id]);

  // Fetch auto-bidding settings and minimum bid information
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (!id || !hasAccess) return;
        
        // Fetch auto-bidding settings
        const settingsResponse = await axios.get(`${BASE_URL}/api/bidding/auto-bidding/settings/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (settingsResponse.data.success && settingsResponse.data.data) {
          const settings = settingsResponse.data.data;
          
          if (settings.enabled) {
            setIsAutoBidding(true);
            setMaxAutoBidAmount(settings.maxAmount.toString());
            setAutoBidIncrement(settings.increment);
          }
        }
        
        // Fetch minimum bid information
        const bidsResponse = await axios.get(`${BASE_URL}/api/bidding/auto-bidding/info/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (bidsResponse.data.success) {
          if (bidsResponse.data.minManualBid) {
            setMinManualBid(bidsResponse.data.minManualBid);
          }
          
          if (bidsResponse.data.minAutoBidAmount) {
            setMinAutoBidAmount(bidsResponse.data.minAutoBidAmount);
          }
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };
    
    fetchSettings();
  }, [id, hasAccess, currentBid]);

  // Refresh connection and data if user is idle for a while
  useEffect(() => {
    const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
    
    const refreshTimer = setInterval(() => {
      if (socket && hasAccess) {
        // Re-join auction to refresh data
        socket.emit('join-auction', id);
      }
    }, REFRESH_INTERVAL);
    
    return () => clearInterval(refreshTimer);
  }, [socket, hasAccess, id]);

  // Notification component
  const Notification = ({ notification }) => {
    if (!notification) return null;
    
    return (
      <div className={`bid-notification notification-${notification.type}`}>
        <div className="notification-title">{notification.title}</div>
        <div className="notification-message">{notification.message}</div>
      </div>
    );
  };

  // Auction header component
  const AuctionHeader = () => (
    <div className="auction-header">
      <div className="property-info">
        <button className="back-button" onClick={handleBackClick}>
          <FaArrowLeft /> Back to Auctions
        </button>
        <h1>{product.propertyType || "Property"} {product.productName && `- ${product.productName}`}</h1>
        <p className="property-location">
          {product.street}, {product.city}, {product.state}
          <span 
            className={`auction-status-label status-${auctionStatus}`}
          >
            {auctionStatus === "active" ? "Live" : 
             auctionStatus === "ended" ? "Ended" : "Live"}
          </span>
        </p>
      </div>
      <div className="auction-status">
        <div className="status-item">
          <span className="status-label">
            <FaUsers /> Participants
          </span>
          <span className="status-value">{participants}</span>
        </div>
        <div className="status-item">
          <span className="status-label">
            <FaClock /> {timeLeft > 0 ? "Time Remaining" : "Auction Status"}
          </span>
          <span className={`status-value time ${timeLeft > 0 && timeLeft < 300 ? 'final-minutes' : ''}`}>
            {timeLeft === null ? (
              "Loading..."
            ) : timeLeft <= 0 ? (
              <span className="ended">Auction Ended</span>
            ) : (
              formatTime(timeLeft)
            )}
          </span>
        </div>
        {auctionExtensionCount > 0 && auctionEndTime && (
          <div className="extension-notice">
            <div className="pulse-dot"></div>
            <span>
              Extended {auctionExtensionCount} {auctionExtensionCount === 1 ? "time" : "times"}
              <span className="end-time">
                (Ends: {auctionEndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );

  // Bidding section component
  const BiddingSection = () => {
    // Calculate suggested bid increments
    const calculateIncrements = () => {
      const base = Math.max(currentBid, 1000);
      return [
        1000,
        Math.round(base * 0.05 / 1000) * 1000,
        Math.round(base * 0.1 / 1000) * 1000
      ].filter((v, i, a) => a.indexOf(v) === i && v >= 1000).sort((a, b) => a - b);
    };

    const suggestedIncrements = calculateIncrements();
    const isAuctionActive = auctionStatus === "active";
    
    return (
      <div className="bidding-section">
        <div className="current-bid">
          <h2>Current Highest Bid</h2>
          <div className="bid-amount">₹{currentBid.toLocaleString()}</div>
          <div className="bidder">
            {currentBidder ? (
              currentBidder.id === user._id ? 
              "Your bid" : 
              `Bid by ${currentBidder.name || "Other bidder"}`
            ) : "No bids yet"}
          </div>
          {currentBidder && currentBidder.id === user._id && auctionStatus === "active" && (
            <div className="winning-indicator">
              <div className="winning-indicator-icon">✓</div>
              You are currently the highest bidder
            </div>
          )}
          {currentBidder && currentBidder.id !== user._id && auctionStatus === "active" && bidHistory.some(bid => bid.currentBidder?.id === user._id) && (
            <div className="losing-indicator">
              <FaExclamationTriangle /> You have been outbid
            </div>
          )}
        </div>

        <form onSubmit={handleBidSubmit} className="bid-form">
          <div className="input-group">
            <input
              type="number"
              value={bidAmount}
              onChange={(e) => {
                setBidAmount(e.target.value);
                bidAmountRef.current = e.target.value;
              }}
              placeholder={`Min bid: ₹${minManualBid > 0 ? minManualBid.toLocaleString() : (currentBid + 1000).toLocaleString()}`}
              disabled={!isAuctionActive || isProcessingBid}
              min={minManualBid > 0 ? minManualBid : currentBid + 1000}
              step="1000"
              required
            />
            <button 
              type="submit" 
              disabled={!isAuctionActive || isProcessingBid}
              className={isProcessingBid ? "processing" : ""}
            >
              {isProcessingBid ? "Processing..." : "Place Bid"}
            </button>
          </div>
          {bidError && <div className="error-message">{bidError}</div>}
          
          <div className="bid-increments">
            <h4>Quick Bid</h4>
            <div className="increment-buttons">
              {suggestedIncrements.map((increment, index) => (
                <button
                  key={increment}
                  type="button"
                  className={`increment-button ${index === 1 ? "recommended" : ""}`}
                  onClick={() => handleIncrementClick(increment)}
                  disabled={!isAuctionActive || isProcessingBid}
                >
                  {index === 1 && <FaBolt size={12} />} +₹{increment.toLocaleString()}
                </button>
              ))}
            </div>
          </div>
        </form>

        <div className="auto-bidding">
          <h3>Auto Bidding</h3>
          <p className="auto-bid-description">
            Set a maximum amount you're willing to pay. The system will automatically bid for you, in increments, up to your maximum.
          </p>
          <div className="auto-bid-form">
            <div className="auto-bid-inputs">
              <input
                type="number"
                value={maxAutoBidAmount}
                onChange={(e) => setMaxAutoBidAmount(e.target.value)}
                placeholder="Maximum bid amount"
                disabled={!isAuctionActive || isAutoBidding}
                min={Math.max(currentBid + 1000, minAutoBidAmount)}
                step="1000"
              />
              <input
                type="number"
                value={autoBidIncrement}
                onChange={(e) => setAutoBidIncrement(e.target.value)}
                placeholder="Bid increment"
                disabled={!isAuctionActive || isAutoBidding}
                min="1000"
                step="1000"
              />
            </div>
            <button
              onClick={toggleAutoBidding}
              disabled={!isAuctionActive || isAutoBidding}
              className={isAutoBidding ? "active" : ""}
            >
              {isAutoBidding ? "Auto-Bidding Active" : "Start Auto-Bidding"}
            </button>
          </div>
          {isAutoBidding && (
            <div className="auto-bid-active">
              <div className="active-icon"></div>
              Auto-bidding is active up to ₹{parseFloat(maxAutoBidAmount).toLocaleString()}
            </div>
          )}
          {autoBidError && <div className="error-message">{autoBidError}</div>}
        </div>
        
        <button
          className="property-details-toggle"
          onClick={() => setShowPropertyDetails(!showPropertyDetails)}
        >
          {showPropertyDetails ? "Hide Property Details" : "Show Property Details"}
        </button>
        
        {showPropertyDetails && <PropertyDetails product={product} />}
      </div>
    );
  };

  // Bid history section component
  const BidHistorySection = () => (
    <div className="bid-history-section">
      <h3>Bid History</h3>
      <div className="bid-history-list">
        {bidHistory.length === 0 ? (
          <div className="no-bids">No bids yet</div>
        ) : (
          bidHistory.map((bid, index) => (
            <div 
              key={`${bid.timestamp}-${index}`} 
              className={`bid-history-item ${bid.currentBidder?.id === user._id ? "my-bid" : ""}`}
            >
              <div className="bid-history-amount">₹{bid.currentBid.toLocaleString()}</div>
              <div className="bid-history-bidder">
                {bid.currentBidder?.id === user._id ? "You" : (bid.currentBidder?.name || "Other bidder")}
                {bid.isAutoBid && <span className="auto-bid-tag">Auto</span>}
              </div>
              <div className="bid-history-time">
                {new Date(bid.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
              </div>
            </div>
          ))
        )}
      </div>
      
      <div className="auction-rules">
        <h4>Auction Rules</h4>
        <ul className="rule-list">
          <li>All bids are binding and cannot be retracted.</li>
          <li>The auction will be extended by 5 minutes if a bid is placed in the last 5 minutes.</li>
          <li>Auto-bidding cannot be disabled once activated.</li>
          <li>Winner must complete the purchase according to terms.</li>
          <li>All bids are subject to verification.</li>
        </ul>
      </div>
    </div>
  );

  // Auction Results Banner (when auction is ended)
  const AuctionResultBanner = () => {
    if (auctionStatus !== "ended") return null;
    
    const isWinner = currentBidder && currentBidder.id === user._id;
    
    return (
      <div className="auction-result-banner">
        <h2 className={`result-title ${isWinner ? 'winning-result' : ''}`}>
          {isWinner ? 'Congratulations! You Won This Auction' : 'Auction Has Ended'}
        </h2>
        <p className="result-message">
          {isWinner 
            ? 'You have successfully won this auction. Our team will contact you with next steps.'
            : currentBidder 
              ? 'This auction has ended. The property has been sold to the highest bidder.'
              : 'This auction has ended with no bids.'
          }
        </p>
        {currentBidder && (
          <div className="result-details">
            Final Price: ₹{currentBid.toLocaleString()}
          </div>
        )}
      </div>
    );
  };

  // Render loading state
  if (loading) {
    return (
      <>
        <NavBackground/>
        <div className="live-auction-loading">
          <LoadingSpinner />
          <p>Loading auction room...</p>
        </div>
      </>
    );
  }
  
  // Render error state if no access
  if (!hasAccess) {
    return (
      <>
        <NavBackground/>
        <div className="live-auction-error">
          <div className="error-icon"><FaExclamationTriangle /></div>
          <h2>Access Denied</h2>
          <p>{error || "You don't have access to this auction"}</p>
          <button className="back-button" onClick={handleBackClick}>
            Back to Auctions
          </button>
        </div>
      </>
    );
  }
  
  // Render error state if no product
  if (!product) {
    return (
      <>
        <NavBackground/>
        <div className="live-auction-error">
          <div className="error-icon"><FaExclamationTriangle /></div>
          <h2>Auction Not Found</h2>
          <p>The requested auction could not be found</p>
          <button className="back-button" onClick={handleBackClick}>
            Back to Auctions
          </button>
        </div>
      </>
    );
  }

  // Main render
  return (
    <>
      <NavBackground />
      <div className="live-auction-room">
        <AuctionHeader />
        
        {auctionStatus === "ended" && <AuctionResultBanner />}
        
        <div className="auction-main">
          <BiddingSection />
          <BidHistorySection />
        </div>
        
        {notification && <Notification notification={notification} />}
      </div>
    </>
  );
};

export default LiveAuctionRoom;