import React from "react";
import "./LandingPage.css";
import image from "../../assets/images/logo/vihara-logo-light.png";
import { useState } from "react";
import axios from "axios";
import BASE_URL from "../../utils/api";
const LandingPage = () => {
  const [connectOption, setConnectOption] = useState(["email"]);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});

  const handleClickConnect = (option) => {
    if (connectOption.includes(option)) {
      const newOptions = connectOption.filter((value) => value !== option);
      setConnectOption(newOptions);
    } else {
      setConnectOption([...connectOption, option]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "First Name is required.";
    if (!formData.lastName.trim())
      newErrors.lastName = "Last Name is required.";
    if (!formData.companyName.trim())
      newErrors.companyName = "Company Name is required.";
    if (!formData.email.trim()) {
      newErrors.email = "Email Address is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email Address is invalid.";
    }
    if (connectOption.includes("phone") && !formData.phone.trim()) {
      newErrors.phone = "Phone Number is required when selecting phone.";
    } else if (
      connectOption.includes("phone") &&
      !/^\d{10}$/.test(formData.phone)
    ) {
      newErrors.phone = "Phone Number must be 10 digits.";
    }
    if (connectOption.length === 0) {
      newErrors.contactMethod = "At least one contact method must be selected.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const dataToSend = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      companyName: formData.companyName,
      email: formData.email,
      phone: connectOption.includes("phone") ? formData.phone : "",
      contactMethods: connectOption,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/user/meeting-form`,
        dataToSend,
        {}
      );
      if (response.data.success) {
        setFormData({
          firstName: "",
          lastName: "",
          companyName: "",
          email: "",
          phone: "",
        });
        setConnectOption(["email"]); // Reset contact options
        setSubmitted(true);
      } else {
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  const handleClick = () => {
    window.location.href = "/";
  };
  return (
    <div className="landing-page-container">
      {/* Navbar */}
      <nav className="landing-page-navbar">
        <div className="landing-page-logo" onClick={handleClick}>
          <img className="logo-small" src={image} alt="Logo" />
        </div>
      </nav>
      {/* Hero Section */}
      <div  style={{display:submitted?"flex":"none",height:"100vh"}} className="landing-page-hero">
        <div  style={{maxWidth:"80%"}} className="landing-page-hero-content">
          <h1>
            Thanks for reaching out! We will be in touch with you shortly.
          </h1>
        </div>
      </div>
      <div
        style={{ display: submitted ? "none" : "" }}
        className="landing-page-hero"
      >
        <div className="landing-page-hero-content">
          <h1>Connecting You to What Truly Counts</h1>
          <p>Meet us in Dallas for the MBA Servicing Conference.</p>
          <p>
            February 4–7, 2025. Schedule time with our team to explore how we
            connect financial partners and service providers using cutting-edge
            solutions. With modern technology, exceptional care, and deep
            industry insight, we’re here to transform your journey in default
            services.
          </p>
        </div>

        {/* Form Section */}
        <div className="landing-page-form">
          <form onSubmit={handleSubmit}>
            <label>First Name *</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
            {errors.firstName && (
              <span className="error">{errors.firstName}</span>
            )}

            <label>Last Name *</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
            {errors.lastName && (
              <span className="error">{errors.lastName}</span>
            )}

            <label>Company Name *</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              required
            />
            {errors.companyName && (
              <span className="error">{errors.companyName}</span>
            )}

            <label>Email Address *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
            {connectOption.includes("phone") && (
              <>
                <label>Phone Number *</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </>
            )}
            <label>Select your preferred contact method *</label>
            <div className="landing-page-radio-group">
              <label>
                <input
                  type="checkbox"
                  onClick={() => handleClickConnect("email")}
                  checked={connectOption.includes("email")}
                  value="email"
                />
                Email
              </label>
              <label>
                <input
                  type="checkbox"
                  onClick={() => handleClickConnect("phone")}
                  checked={connectOption.includes("phone")}
                  value="phone"
                />
                Phone
              </label>
            </div>
            {errors.contactMethod && (
              <span className="error">{errors.contactMethod}</span>
            )}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
const Meeting = () => {
  return (
    <div className="landing-page landing-page--meeting">
      <div className="landing-page__video-container">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/VIDEO_ID"
          title="Meet the Vihara team at MBA's Servicing Solutions Conference & Expo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="landing-page__content">
        <h1 className="landing-page__title">
          The MBA Servicing Solutions Conference & Expo
        </h1>
        <p className="landing-page__description">
          Change is the only constant in mortgage servicing. Schedule a meeting
          today to learn how we can help you navigate regulatory shifts and
          address your specific challenges.
        </p>
        <button className="landing-page__schedule-meeting-button">
          Schedule a meeting
        </button>
      </div>
    </div>
  );
};
const MortgageServicing = () => {
  return (
    <div className="mortgage-servicing-container">
      <div className="mortgage-servicing-header">
        <h2>
          Technology designed to provide clear insights for justifying asset
          decisions.
        </h2>
        <p>
          Vihara’s innovative EXOS One Marketplace® consolidates essential
          information for servicers and investors managing default portfolios
          into one powerful, customizable tool. Tailored to each client’s goals,
          it adapts to evolving regulations for informed asset decision-making.
        </p>
      </div>

      <div className="mortgage-servicing-wheel">
        <img src="https://img.freepik.com/premium-photo/house-signers-signing-signature-loan-document-home-ownership-with-real-estate-agents-ownership-mortgage-real-estate-property-investment-home-insurance_11304-2037.jpg?w=740" />
      </div>
    </div>
  );
};
const AssetDecisions = () => {
  return (
    <div className="landing-page landing-page--meeting">
      <div className="landing-page__video-container">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/VIDEO_ID"
          title="Meet the Vihara team at MBA's Servicing Solutions Conference & Expo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="landing-page__content">
        <h1 className="landing-page__title">
          Technology designed to help you make asset decisions with clear,
          data-driven justification.
        </h1>
        <p className="landing-page__description">
          Vihara’s EXOS One Marketplace® consolidates key data for servicers and
          investors into a single tool for informed asset decisions.
          Customizable to client goals, it updates with new regulations.
        </p>
        <button className="landing-page__schedule-meeting-button">
          Schedule a meeting
        </button>
      </div>
    </div>
  );
};

const ClientFeedback = () => {
  return (
    <div className="client-feedback-container">
      <h2 className="client-feedback-title">Client Testimonials</h2>
      <div className="client-feedback-card">
        <p className="client-feedback-quote">
          “We always seek the best, and Vihara consistently exceeds our
          expectations. They went the extra mile to tailor EXOS One Marketplace
          to our needs, allowing my team to spend less time on research and more
          on impactful decision-making.”
        </p>
        <p className="client-feedback-author">
          Nikum Patel, SVP, Transaction Management, Binomial Real Estate
        </p>
      </div>
    </div>
  );
};
