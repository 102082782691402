// ReviewStep.jsx
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ReviewStep = ({ 
  formData, 
  handleInputChange, 
  product, 
  currentBid, 
  buyersPremium,
  buyerType 
}) => {
  const totalPurchasePrice = currentBid + buyersPremium;

  return (
    <div className="auction-registration-form-step-content">
      <div className="auction-registration-form-section">
        <div className="auction-registration-form-section-title">REVIEW YOUR INFORMATION</div>

        {/* Property Details */}
        <div className="auction-registration-form-review-section">
          <h3>Property Details</h3>
          <div className="auction-registration-form-review-item">
            <span className="auction-registration-form-review-label">Property Address:</span>
            <span className="auction-registration-form-review-value">{product?.street}, {product?.city}, {product?.state}</span>
          </div>
          <div className="auction-registration-form-review-item">
            <span className="auction-registration-form-review-label">Purchase Price:</span>
            <span className="auction-registration-form-review-value">${currentBid.toLocaleString()}</span>
          </div>
          <div className="auction-registration-form-review-item">
            <span className="auction-registration-form-review-label">Buyer's Premium:</span>
            <span className="auction-registration-form-review-value">${buyersPremium.toLocaleString()}</span>
          </div>
          <div className="auction-registration-form-review-item">
            <span className="auction-registration-form-review-label">Total Price:</span>
            <span className="auction-registration-form-review-value">${totalPurchasePrice.toLocaleString()}</span>
          </div>
        </div>
        {/* Buyer Information */}
        <div className="auction-registration-form-review-section">
          <h3>Buyer Information</h3>
          <div className="auction-registration-form-review-item">
            <span className="auction-registration-form-review-label">Buyer Type:</span>
            <span className="auction-registration-form-review-value">
              {buyerType === 'individual' ? 'Individual' : 'Company'}
            </span>
          </div>
          {buyerType === 'company' && (
            <>
              <div className="auction-registration-form-review-item">
                <span className="auction-registration-form-review-label">Company:</span>
                <span className="auction-registration-form-review-value">{formData.company}</span>
              </div>
              <div className="auction-registration-form-review-item">
                <span className="auction-registration-form-review-label">Entity Type:</span>
                <span className="auction-registration-form-review-value">{formData.entityType}</span>
              </div>
              <div className="auction-registration-form-review-item">
                <span className="auction-registration-form-review-label">Tax ID:</span>
                <span className="auction-registration-form-review-value">{formData.taxId}</span>
              </div>
            </>
          )}
          <div className="auction-registration-form-review-item">
            <span className="auction-registration-form-review-label">Name:</span>
            <span className="auction-registration-form-review-value">
              {formData.firstName} {formData.lastName}
            </span>
          </div>
          <div className="auction-registration-form-review-item">
            <span className="auction-registration-form-review-label">Contact:</span>
            <span className="auction-registration-form-review-value">
              {formData.mobilePhone} | {formData.email}
            </span>
          </div>
          <div className="auction-registration-form-review-item">
            <span className="auction-registration-form-review-label">Address:</span>
            <span className="auction-registration-form-review-value">
              {`${formData.address}, ${formData.city}, ${formData.state} ${formData.zipCode}`}
            </span>
          </div>
        </div>
        {/* Legal Acknowledgments */}
        <div className="auction-registration-form-review-section">
          <h3>Legal Acknowledgments</h3>
          <div className="auction-registration-form-review-checklist">
            {formData.agreeToTerms && (
              <div className="auction-registration-form-review-check">
                <FontAwesomeIcon icon={faCheck} className="text-green-500" /> Terms and Conditions Accepted
              </div>
            )}
            {formData.acknowledgeInspection && (
              <div className="auction-registration-form-review-check">
                <FontAwesomeIcon icon={faCheck} className="text-green-500" /> Property Inspection Acknowledged
              </div>
            )}
            {formData.understandFinancing && (
              <div className="auction-registration-form-review-check">
                <FontAwesomeIcon icon={faCheck} className="text-green-500" /> Financing Requirements Understood
              </div>
            )}
            {formData.acceptPropertyCondition && (
              <div className="auction-registration-form-review-check">
                <FontAwesomeIcon icon={faCheck} className="text-green-500" /> Property Condition Accepted
              </div>
            )}
            {formData.acknowledgeClosingTimeline && (
              <div className="auction-registration-form-review-check">
                <FontAwesomeIcon icon={faCheck} className="text-green-500" /> Closing Timeline Acknowledged
              </div>
            )}
          </div>
        </div>

        {/* Information Confirmation */}
        <div className="auction-registration-form-disclosure-item">
          <label className="auction-registration-form-checkbox-label">
            <input
              type="checkbox"
              checked={formData.confirmInformation}
              onChange={(e) => handleInputChange('confirmInformation', e.target.checked)}
              className="auction-registration-form-checkbox"
            />
            <span>I confirm that all the information provided above is accurate and complete.</span>
          </label>
          <div className="auction-registration-form-disclosure-text">
            By checking this box, I certify that the information I have provided is true and correct to the best of my knowledge.
            I understand that false statements may affect my ability to participate in this auction.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewStep;