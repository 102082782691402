import React, { useState } from 'react';
import './Sidebar.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faNoteSticky, faArrowCircleLeft, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const AdminPanelSidebar = ({ toggleSidebar,handleMainContent }) => {
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isAuctionOpen, setIsAuctionOpen] = useState(false);

  const handleUserClick = () => {
    setIsUserOpen(!isUserOpen);
  };

  const handleAuctionClick = () => {
    setIsAuctionOpen(!isAuctionOpen);
  };

  return (
    <div className="admin-panel-sidebar">
      <div className="admin-panel-sidebar-header">
       <span>RLAuction</span> 
        <FontAwesomeIcon
          className="admin-panel-sidebar-close-icon"
          icon={faArrowCircleLeft}
          onClick={() => toggleSidebar(false)}
        />
      </div>
      <div className="admin-panel-sidebar-nav">
        <ul>
          <li>
            <FontAwesomeIcon className="admin-sidebar-icon" icon={faHome} /> Dashboard
          </li>
          <li onClick={handleUserClick} className="admin-sidebar-accordion">
            <FontAwesomeIcon className="admin-sidebar-icon" icon={faUser} /> User
            <FontAwesomeIcon className="admin-sidebar-icon-chevron-1" icon={isUserOpen ? faChevronUp : faChevronDown} />
          </li>
          <div className={`admin-panel-sidebar-sublist ${isUserOpen ? 'open' : 'closed'}`}>
            <ul>
              <li onClick={()=>handleMainContent("addUser")} >Add User</li>
              <li onClick={()=>handleMainContent("manageUser")}>Manage User</li>
            </ul>
          </div>
          <li onClick={handleAuctionClick} className="admin-sidebar-accordion">
            <FontAwesomeIcon className="admin-sidebar-icon" icon={faNoteSticky} /> Auction
            <FontAwesomeIcon className="admin-sidebar-icon-chevron-2" icon={isAuctionOpen ? faChevronUp : faChevronDown} />
          </li>
          <div className={`admin-panel-sidebar-sublist ${isAuctionOpen ? 'open' : 'closed'}`}>
            <ul>
              <li onClick={()=>handleMainContent("addAuction")}>Add Auction</li>
              <li onClick={()=>handleMainContent("manangeAuction")}>Manage Auction</li>
              <li onClick={() => handleMainContent("manageRegistrations")}>Manage Registrations</li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  );
};

AdminPanelSidebar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  handleMainContent:PropTypes.func.isRequired
};

export default AdminPanelSidebar;
