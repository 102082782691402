import React, { useState, useEffect } from 'react';
import './SavedSearches.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress } from '@mui/material';
import BASE_URL from '../../../utils/api';

const SavedSearches = () => {
  const [searches, setSearches] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchSavedSearches();
    } else {
      setLoading(false);
    }
  }, [user]);

  const fetchSavedSearches = async () => {
    setLoading(true);
    try {
      console.log(user)
      const response = await axios.get(`${BASE_URL}/api/user/save-searches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });
      setSearches(response.data);
    } catch (error) {
      console.error("Error fetching saved searches:", error);
      toast.error("Failed to fetch saved searches");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSearch = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/user/save-searches/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSearches(searches.filter(search => search._id !== id));
      toast.success("Search removed successfully");
    } catch (error) {
      console.error("Error deleting saved search:", error);
      toast.error("Failed to delete saved search");
    }
  };

  const handleRunSearch = (url) => {
    navigate(url);
  };

  // Use the name field directly from the saved search
  const formatSearchName = (search) => {
    return search.name || 'Untitled Search';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="portal-my-searches-container">
      <div className="portal-my-searches-header">
        <h2 className='portal-profile-header portal-profile-my-search-header'>My Searches</h2>
      </div>
      
      <div className="portal-my-searches-content">
        {loading ? (
          <div className="search-loading">
            <CircularProgress size={30} />
          </div>
        ) : searches.length === 0 ? (
          <p>Save your searches so we can tell you about new listings that match what you're looking for.</p>
        ) : (
          <div className="saved-searches-list">
            {searches.map((search) => (
              <div key={search._id} className="saved-search-item">
                <div className="saved-search-details">
                  <div>{formatSearchName(search)}</div>
                  <div className="search-date">Saved on {formatDate(search.dateCreated)}</div>
                </div>
                <div className="saved-search-actions">
                  <Button 
                    variant="contained" 
                    onClick={() => handleRunSearch(search.url)}
                    sx={{ backgroundColor: '#0384fb', marginRight: '10px' }}
                  >
                    Run Search
                  </Button>
                  <Button 
                    variant="outlined" 
                    onClick={() => handleDeleteSearch(search._id)}
                    sx={{ color: '#ff4d4f', borderColor: '#ff4d4f' }}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SavedSearches;