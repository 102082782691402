const PropertyDetails = ({ product }) => {
    if (!product) return null;
    
    return (
      <div className="property-details">
        <h3>Property Details</h3>
        
        <div className="property-stats">
          <div className="stat-item">
            <div className="stat-value">{product.beds}</div>
            <div className="stat-label">Bedrooms</div>
          </div>
          <div className="stat-item">
            <div className="stat-value">{product.baths}</div>
            <div className="stat-label">Bathrooms</div>
          </div>
          <div className="stat-item">
            <div className="stat-value">{product.squareFootage.toLocaleString()}</div>
            <div className="stat-label">Sq. Ft.</div>
          </div>
        </div>
        
        <div className="property-description">
          <p>{product.propertyDescription}</p>
        </div>
        
        <div className="property-details-table">
          <div className="detail-row">
            <div className="detail-label">Property Type</div>
            <div className="detail-value">{product.propertyType}</div>
          </div>
          <div className="detail-row">
            <div className="detail-label">Year Built</div>
            <div className="detail-value">{product.yearBuilt}</div>
          </div>
          <div className="detail-row">
            <div className="detail-label">Lot Size</div>
            <div className="detail-value">{product.lotSize} acres</div>
          </div>
          <div className="detail-row">
            <div className="detail-label">Monthly HOA</div>
            <div className="detail-value">₹{product.monthlyHOADues.toLocaleString()}</div>
          </div>
          <div className="detail-row">
            <div className="detail-label">APN</div>
            <div className="detail-value">{product.apn}</div>
          </div>
        </div>
      </div>
    );
  };
  export default PropertyDetails